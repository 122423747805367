import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const Review = ({ fileData, headerMapping }) => {
  // First, get all the keys that were NOT mapped to "do_not_map"
  const validMappings = Object.entries(headerMapping)
    .filter(([_, mappedValue]) => mappedValue !== "do_not_map")
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  const mappedData = fileData.map(row => {
    const newRow = {};
    // Only include fields that weren't mapped to "do_not_map"
    Object.keys(validMappings).forEach(key => {
      const mappedKey = headerMapping[key];
      newRow[mappedKey] = row[key];
    });
    return newRow;
  });

  if (!mappedData.length || !Object.keys(mappedData[0] || {}).length) {
    return (
      <Box>
        <Typography variant="h6" gutterBottom>Review</Typography>
        <Typography>No mapped fields to display.</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Review</Typography>
      <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {Object.keys(mappedData[0] || {}).map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {mappedData.map((row, index) => (
              <TableRow key={index}>
                {Object.values(row).map((value, cellIndex) => (
                  <TableCell key={cellIndex}>{value}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default Review;
