import React, { useContext } from 'react';
import { AuthContext } from "../../shared/context/auth-context";
import AudienceBuilder from './AudienceBuilder';
import AudienceDataProvider from '../components/AudienceDataProvider';

const AudienceBuilderWrapper = () => {
  const auth = useContext(AuthContext);
  
  return (
    <AudienceDataProvider auth={auth}>
      <AudienceBuilder />
    </AudienceDataProvider>
  );
};

export default AudienceBuilderWrapper;
