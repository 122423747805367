import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Alert,
} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const UnmappedFieldsDialog = ({
  open,
  onClose,
  onConfirm,
  unmappedFields,
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          position: 'relative',
          zIndex: 9999,
        },
        '& .MuiBackdrop-root': {
          zIndex: 9998,
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" gap={1}>
          <WarningAmberIcon sx={{ color: 'warning.main' }} />
          <Typography variant="h6">
            Unmapped Fields Detected
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
          The following fields from your file have not been mapped:
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 2 }}>
          {unmappedFields.map((field) => (
            <Alert key={field} severity="warning" sx={{ '& .MuiAlert-message': { width: '100%' } }}>
              {field}
            </Alert>
          ))}
        </Box>
        <Typography variant="body2" color="text.secondary">
          If you proceed, these fields will be marked as "Do Not Map" and their data will not be included in the upload.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 2, gap: 1 }}>
        <Button 
          onClick={onClose}
          variant="outlined"
        >
          Back to Mapping
        </Button>
        <Button 
          onClick={onConfirm}
          variant="contained"
          color="primary"
        >
          Proceed Without These Fields
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnmappedFieldsDialog;
