import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Shield } from "lucide-react";
import { Card, CardHeader, CardContent } from "./ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Badge } from "./ui/badge";
import { Checkbox } from "./ui/checkbox";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { Button } from "./ui/button";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { toast } from 'react-toastify';
import userImage from "./user.png";

const RoleSelector = ({ onRoleChange }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUser, setIsUser] = useState(true);

  const handleAdminChange = (checked) => {
    setIsAdmin(checked);
    setIsUser(!checked);
    onRoleChange(checked ? 'admin' : 'user');
  };

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex items-center space-x-2">
        <Checkbox 
          id="user-role"
          checked={isUser}
          disabled={isAdmin}
          className="h-4 w-4"
        />
        <label
          htmlFor="user-role"
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          User
        </label>
      </div>
      <div className="flex items-center space-x-2">
        <Checkbox 
          id="admin-role"
          checked={isAdmin}
          onCheckedChange={handleAdminChange}
          className="h-4 w-4"
        />
        <label
          htmlFor="admin-role"
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          Admin
        </label>
      </div>
    </div>
  );
};

const UserItem = (props) => {
  const [leadCount, setLeadCount] = useState(0);
  const [uploadCount, setUploadCount] = useState(0);
  const [selectedRole, setSelectedRole] = useState("user");
  const [isActivating, setIsActivating] = useState(false);
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const status = props.status || "Offline";

  const statusColors = {
    Available: "bg-green-500",
    Away: "bg-yellow-500",
    Busy: "bg-red-500",
    Offline: "bg-gray-400",
  };

  const getInitials = (name) => {
    return name
      .split(" ")
      .map((part) => part[0])
      .join("")
      .toUpperCase();
  };

  const formatTeamName = (team, division) => {
    if (!team || !division) return team;
    const prefix = `${division} - `;
    return team.startsWith(prefix) ? team.substring(prefix.length) : team;
  };

  useEffect(() => {
    const fetchUserActivity = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/users/activity/${props.id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          }
        );
        setLeadCount(responseData.lead_count || 0);
        if (!props.api_account) {
          setUploadCount(responseData.upload_count || 0);
        }
      } catch (error) {
        console.error("Error fetching user activity:", error);
      }
    };

    if (auth && auth.token && (props.api_account || props.active === true)) {
      fetchUserActivity();
    }
  }, [sendRequest, props.id, props.api_account, props.active, auth]);

  const handleActivate = async () => {
    try {
      setIsActivating(true);
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/${props.id}/activate`,
        "POST",
        JSON.stringify(selectedRole),
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        }
      );
      
      toast.success('User activated successfully', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      if (props.onActivate) {
        props.onActivate(props.id);
      }
    } catch (error) {
      toast.error('Failed to activate user', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setIsActivating(false);
    }
  };

  const renderContent = () => {
    if (props.api_account) {
      return (
        <div className="grid grid-cols-1 gap-2 bg-gray-50 rounded-lg p-2">
          <div className="text-center">
            <p className="font-bold text-blue-600 text-base leading-none mb-0.5">
              {leadCount}
            </p>
            <p className="text-xs text-gray-600 leading-none">Leads</p>
          </div>
        </div>
      );
    }

    if (props.active === false && auth.role === 'admin') {
      return (
        <div className="flex items-center justify-between">
          <RoleSelector onRoleChange={setSelectedRole} />
          <Button 
            onClick={handleActivate}
            disabled={isActivating}
            className="w-24"
          >
            {isActivating ? "Activating..." : "Activate"}
          </Button>
        </div>
      );
    }

    if (props.active === true) {
      return (
        <div className="grid grid-cols-2 gap-2 bg-gray-50 rounded-lg p-2">
          <div className="text-center">
            <p className="font-bold text-blue-600 text-base leading-none mb-0.5">
              {leadCount}
            </p>
            <p className="text-xs text-gray-600 leading-none">Leads</p>
          </div>
          <div className="text-center">
            <p className="font-bold text-green-600 text-base leading-none mb-0.5">
              {uploadCount}
            </p>
            <p className="text-xs text-gray-600 leading-none">Uploads</p>
          </div>
        </div>
      );
    }

    return null;
  };

  const cardContent = (
    <Card className={`h-44 transition-all duration-300 hover:shadow-lg ${!props.api_account && props.active === false ? 'opacity-75' : ''}`}>
      <CardHeader className="pb-0 pt-4 px-4">
        <div className="flex justify-between items-start">
          <div className="flex items-center space-x-3">
            <div className="relative">
              <Avatar className="h-12 w-12 border-2 border-white">
                <AvatarImage
                  src={props.image || userImage}
                  alt={props.username}
                />
                <AvatarFallback>{getInitials(props.username)}</AvatarFallback>
              </Avatar>
              {props.api_account !== true && props.active && (
                <div
                  className={`absolute bottom-0 right-0 w-3 h-3 rounded-full border-2 border-white ${
                    statusColors[status]
                  }`}
                />
              )}
            </div>
            <div className="flex flex-col min-h-[52px] justify-between">
              <div>
                <div className="flex items-center space-x-2">
                  <h3 className="font-semibold text-base mb-0 leading-none">
                    {props.username}
                  </h3>
                  {!props.api_account && props.active === false && (
                    <Badge variant="secondary" className="text-xs">
                      Inactive
                    </Badge>
                  )}
                </div>
                {props.team && (
                  <p className="text-sm text-gray-600 leading-tight mb-1">
                    {formatTeamName(props.team, props.division)}
                  </p>
                )}
              </div>
              {props.division && (
                <Badge
                  variant={props.division === "HQ" ? "default" : "secondary"}
                  className="text-xs w-fit px-2 py-0.5"
                >
                  {props.division}
                </Badge>
              )}
            </div>
          </div>
          {props.role === "admin" && (
            <TooltipProvider>
              <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                  <div className="p-1 rounded-full hover:bg-gray-100 transition-colors">
                    <Shield className="text-blue-500" size={16} />
                  </div>
                </TooltipTrigger>
                <TooltipContent>Admin User</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
      </CardHeader>

      <CardContent className="px-4 pb-4 pt-2">
        {renderContent()}
      </CardContent>
    </Card>
  );

  return (
    <li className="user-item">
      {(props.api_account || props.active === true) ? (
        <Link to={`/${props.id}/leads`} className="block">
          {cardContent}
        </Link>
      ) : (
        cardContent
      )}
    </li>
  );
};

export default UserItem;
