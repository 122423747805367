import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import DeleteConfirmationDialog from "../../teamhub/components/DeleteConfirmationDialog";
import CalendarView from "../components/CalendarView";
import { Card } from "../../teamhub/components/ui/card";
import { Button } from "../../teamhub/components/ui/button";
import { Input } from "../../teamhub/components/ui/input";
import AddEventDialog from "../components/AddEventDialog";
import {
  Calendar,
  Search,
  Filter,
  LayoutList,
  CalendarDays,
  ChevronDown,
  Clock,
  Users,
  Plus,
  ChevronRight,
  ChevronUp,
  X,
  Edit,
  Trash2,
} from "lucide-react";

const getEventColor = (type) => {
  switch (type) {
    case "meeting":
      return "text-blue-600 bg-blue-50 border-blue-200";
    case "deadline":
      return "text-purple-600 bg-purple-50 border-purple-200";
    case "conference":
      return "text-green-600 bg-green-50 border-green-200";
    case "webinar":
      return "text-orange-600 bg-orange-50 border-orange-200";
    case "review":
      return "text-pink-600 bg-pink-50 border-pink-200";
    default:
      return "text-gray-600 bg-gray-50 border-gray-200";
  }
};

const EventCard = ({ event, onDelete, onUpdate, isCurrentUser, auth }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const formatEventDate = (startDate, endDate, time) => {
    const start = new Date(startDate);
    const end = endDate ? new Date(endDate) : start;

    if (startDate === endDate || !endDate) {
      return `${start.toLocaleDateString()} ${time ? `at ${time}` : ""}`;
    }
    return `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`;
  };

  const handleDelete = () => {
    setShowDeleteDialog(false);
    onDelete(event.event_id);
  };

  return (
    <>
      <Card className="overflow-hidden">
        <div className="p-6">
          <div className="flex items-start">
            <div
              className={`flex-shrink-0 w-12 h-12 rounded-lg ${getEventColor(event.type)} flex items-center justify-center mr-4`}
            >
              <Calendar className="h-6 w-6" />
            </div>
            <div className="flex-1 min-w-0">
              <div className="flex items-start justify-between">
                <div className="flex-1">
                  <div className="flex items-center justify-between">
                    <h3 className="text-lg font-medium text-gray-900">
                      {event.title}
                    </h3>
                    <button
                      onClick={() => setIsExpanded(!isExpanded)}
                      className="ml-4 p-1 hover:bg-gray-100 rounded-full"
                    >
                      {isExpanded ? (
                        <ChevronUp className="h-5 w-5 text-gray-500" />
                      ) : (
                        <ChevronRight className="h-5 w-5 text-gray-500" />
                      )}
                    </button>
                  </div>
                  <div className="mt-1 flex items-center text-sm text-gray-500 space-x-4">
                    <div className="flex items-center">
                      <Clock className="h-4 w-4 mr-1" />
                      <span>
                        {formatEventDate(
                          event.startDate,
                          event.endDate,
                          event.time,
                        )}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <Users className="h-4 w-4 mr-1" />
                      <span>{event.attendees?.length || 0} attendees</span>
                    </div>
                  </div>
                </div>
                <span
                  className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize ${getEventColor(event.type)}`}
                >
                  {event.type}
                </span>
              </div>

              {isExpanded && (
                <div className="mt-4 space-y-4">
                  {event.description && (
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <h4 className="text-sm font-medium text-gray-900 mb-2">
                        Description
                      </h4>
                      <p className="text-sm text-gray-600">
                        {event.description}
                      </p>
                    </div>
                  )}

                  <div className="border-t pt-4">
                    <h4 className="text-sm font-medium text-gray-900 mb-2">
                      Attendees
                    </h4>
                    <div className="grid grid-cols-2 gap-4">
                      {event.attendeeNames?.map((attendee, index) => (
                        <div
                          key={index}
                          className="flex items-center space-x-2"
                        >
                          <div className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center">
                            <span className="text-xs font-medium text-gray-600">
                              {attendee.charAt(0)}
                            </span>
                          </div>
                          <div>
                            <p className="text-sm font-medium text-gray-900">
                              {attendee}
                            </p>
                            <p className="text-xs text-gray-500">
                              {index === 0 ? "Organizer" : "Attendee"}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="border-t pt-4 flex justify-between items-center">
                    <div className="space-x-2">
                      <div className="flex space-x-2">
                        {isCurrentUser && (
                          <>
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => onUpdate(event)}
                            >
                              <Edit className="h-4 w-4 mr-2" />
                              Edit
                            </Button>
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => setShowDeleteDialog(true)}
                            >
                              <Trash2 className="h-4 w-4 mr-2" />
                              Delete
                            </Button>
                          </>
                        )}
                      </div>
                      {event.location && (
                        <div className="text-sm text-gray-500">
                          <span>Location: {event.location}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>

      <DeleteConfirmationDialog
        isOpen={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onConfirm={handleDelete}
        eventTitle={event.title}
        isLoading={false}
      />
    </>
  );
};

const Events = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [events, setEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterType, setFilterType] = useState("all");
  const [filterDate, setFilterDate] = useState("all");
  const [viewMode, setViewMode] = useState("list"); // 'list' or 'calendar'
  const [showTypeDropdown, setShowTypeDropdown] = useState(false);
  const [showDateDropdown, setShowDateDropdown] = useState(false);
  const [isAddEventOpen, setIsAddEventOpen] = useState(false);
  const [eventToEdit, setEventToEdit] = useState(null);
  const auth = useContext(AuthContext);

  const fetchEvents = async () => {
    if (!auth?.token) return;

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/events/all`,
        "GET",
        null,
        {
          Authorization: `Bearer ${auth.token}`,
        },
      );

      // Process events to add status based on dates
      const processedEvents = responseData.map((event) => {
        const startDate = new Date(event.startDate);
        const endDate = event.endDate ? new Date(event.endDate) : startDate;
        const now = new Date();

        let status = "upcoming";
        if (now > endDate) {
          status = "completed";
        } else if (now >= startDate && now <= endDate) {
          status = "in-progress";
        }

        return {
          ...event,
          status,
        };
      });

      setEvents(processedEvents);
    } catch (err) {
      console.error("Error fetching events:", err);
    }
  };

  useEffect(() => {
    if (auth?.token) {
      fetchEvents();
    }
  }, [auth]);

  const handleDeleteEvent = async (eventId) => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/events/${eventId}`,
        "DELETE",
        null,
        {
          Authorization: `Bearer ${auth.token}`,
        },
      );
      setEvents(events.filter((event) => event.event_id !== eventId));
    } catch (err) {
      console.error("Error deleting event:", err);
    }
  };

  const handleUpdateEvent = (event) => {
    setEventToEdit(event);
    setIsAddEventOpen(true);
  };

  const filteredEvents = events.filter((event) => {
    if (!event) return false;

    const matchesSearch =
      event.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.description?.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesType = filterType === "all" || event.type === filterType;

    // Date filtering
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const weekFromNow = new Date(today);
    weekFromNow.setDate(weekFromNow.getDate() + 7);
    const monthFromNow = new Date(today);
    monthFromNow.setMonth(monthFromNow.getMonth() + 1);

    let matchesDate = true;
    if (filterDate === "today") {
      const eventDate = new Date(event.startDate);
      matchesDate =
        eventDate >= today &&
        eventDate < new Date(today.getTime() + 24 * 60 * 60 * 1000);
    } else if (filterDate === "week") {
      const eventDate = new Date(event.startDate);
      matchesDate = eventDate >= today && eventDate <= weekFromNow;
    } else if (filterDate === "month") {
      const eventDate = new Date(event.startDate);
      matchesDate = eventDate >= today && eventDate <= monthFromNow;
    }

    return matchesSearch && matchesType && matchesDate;
  });

  return (
    <div className="p-8 pb-20">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center space-x-4">
          <div className="flex space-x-2 bg-gray-100 rounded-lg p-1">
            <button
              onClick={() => setViewMode("list")}
              className={`p-2 rounded ${viewMode === "list" ? "bg-white shadow" : "hover:bg-gray-200"}`}
            >
              <LayoutList className="h-4 w-4" />
            </button>
            <button
              onClick={() => setViewMode("calendar")}
              className={`p-2 rounded ${viewMode === "calendar" ? "bg-white shadow" : "hover:bg-gray-200"}`}
            >
              <CalendarDays className="h-4 w-4" />
            </button>
          </div>
          <Button onClick={() => setIsAddEventOpen(true)}>
            <Plus className="h-4 w-4 mr-2" />
            Add Event
          </Button>
        </div>
      </div>
      {/* Filters */}
      <div className="mb-6 flex gap-4">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
          <Input
            type="text"
            placeholder="Search events..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10"
          />
        </div>

        {/* Type Filter */}
        <div className="relative">
          <button
            onClick={() => setShowTypeDropdown(!showTypeDropdown)}
            className="inline-flex items-center gap-2 px-4 py-2 border rounded-lg text-sm text-gray-700 hover:bg-gray-50"
          >
            <Filter className="h-4 w-4" />
            Filter by type
            <ChevronDown className="h-4 w-4" />
          </button>
          {showTypeDropdown && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border z-10">
              <div className="py-1">
                {[
                  "all",
                  "meeting",
                  "deadline",
                  "conference",
                  "webinar",
                  "review",
                ].map((type) => (
                  <button
                    key={type}
                    onClick={() => {
                      setFilterType(type);
                      setShowTypeDropdown(false);
                    }}
                    className={`block w-full text-left px-4 py-2 text-sm ${
                      filterType === type ? "bg-gray-100" : "hover:bg-gray-50"
                    }`}
                  >
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Date Filter */}
        <div className="relative">
          <button
            onClick={() => setShowDateDropdown(!showDateDropdown)}
            className="inline-flex items-center gap-2 px-4 py-2 border rounded-lg text-sm text-gray-700 hover:bg-gray-50"
          >
            <Calendar className="h-4 w-4" />
            {filterDate === "all"
              ? "All dates"
              : filterDate === "today"
                ? "Today"
                : filterDate === "week"
                  ? "This week"
                  : "This month"}
            <ChevronDown className="h-4 w-4" />
          </button>
          {showDateDropdown && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border z-10">
              <div className="py-1">
                {["all", "today", "week", "month"].map((date) => (
                  <button
                    key={date}
                    onClick={() => {
                      setFilterDate(date);
                      setShowDateDropdown(false);
                    }}
                    className={`block w-full text-left px-4 py-2 text-sm ${
                      filterDate === date ? "bg-gray-100" : "hover:bg-gray-50"
                    }`}
                  >
                    {date === "all"
                      ? "All dates"
                      : date === "today"
                        ? "Today"
                        : date === "week"
                          ? "This week"
                          : "This month"}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Main Content */}
      {isLoading ? (
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 rounded w-1/4"></div>
          <div className="space-y-3">
            {[1, 2, 3].map((i) => (
              <div key={i} className="h-32 bg-gray-200 rounded"></div>
            ))}
          </div>
        </div>
      ) : viewMode === "list" ? (
        <div className="space-y-4">
          {filteredEvents.map((event) => (
            <EventCard
              key={event.event_id}
              event={event}
              onDelete={handleDeleteEvent}
              onUpdate={handleUpdateEvent}
              isCurrentUser={event.created_by === auth.user_id}
              auth={auth}
            />
          ))}

          {filteredEvents.length === 0 && (
            <div className="text-center py-12">
              <Calendar className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No events found
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {searchTerm || filterType !== "all" || filterDate !== "all"
                  ? "Try adjusting your search or filter settings"
                  : "Get started by creating a new event"}
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className="bg-white rounded-lg border">
          <CalendarView
            events={filteredEvents}
            auth={auth}
            hideAddButton={true}
          />
        </div>
      )}
      {/* Add/Edit Event Modal */}
      <AddEventDialog
        isOpen={isAddEventOpen}
        onClose={() => {
          setIsAddEventOpen(false);
          setEventToEdit(null);
        }}
        onEventAdded={fetchEvents}
        eventToEdit={eventToEdit}
      />
      <ErrorModal error={error} onClear={clearError} />
    </div>
  );
};

export default Events;
