import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../teamhub/components/ui/dialog';
import { Button } from '../../teamhub/components/ui/button';
import { Input } from '../../teamhub/components/ui/input';
import { Loader2, X, Calendar as CalendarIcon, Clock } from 'lucide-react';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { useContext } from 'react';
import { AuthContext } from '../../shared/context/auth-context';

const AddEventDialog = ({ isOpen, onClose, onEventAdded, eventToEdit = null }) => {
  const { isLoading, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const [formData, setFormData] = useState({
    title: eventToEdit?.title || '',
    startDate: eventToEdit?.startDate?.split('T')[0] || '',
    endDate: eventToEdit?.endDate?.split('T')[0] || '',
    time: eventToEdit?.time || '',
    type: eventToEdit?.type || 'meeting',
    description: eventToEdit?.description || '',
    location: eventToEdit?.location || '',
    isMultiDay: eventToEdit ? !!eventToEdit.endDate : false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const eventData = {
        title: formData.title,
        startDate: formData.startDate,
        endDate: formData.isMultiDay ? formData.endDate : formData.startDate,
        time: formData.isMultiDay ? null : formData.time,
        type: formData.type,
        description: formData.description,
        location: formData.location,
      };

      if (eventToEdit) {
        await sendRequest(
          `${process.env.REACT_APP_API_URL}/events/${eventToEdit.event_id}`,
          'PUT',
          JSON.stringify(eventData),
          {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          }
        );
      } else {
        await sendRequest(
          `${process.env.REACT_APP_API_URL}/events/create`,
          'POST',
          JSON.stringify(eventData),
          {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          }
        );
      }

      onEventAdded();
      onClose();
    } catch (err) {
      console.error('Error saving event:', err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <div className="flex items-center justify-between mb-4">
            <DialogTitle>{eventToEdit ? 'Edit Event' : 'Add New Event'}</DialogTitle>
            <button
              onClick={onClose}
              className="rounded-full p-1 hover:bg-gray-100"
            >
              <X className="h-4 w-4 text-gray-500" />
            </button>
          </div>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Event Title
            </label>
            <Input
              type="text"
              name="title"
              required
              value={formData.title}
              onChange={handleInputChange}
              className="w-full"
              placeholder="Enter event title"
            />
          </div>

          <div className="space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="isMultiDay"
                checked={formData.isMultiDay}
                onChange={handleInputChange}
                className="h-4 w-4 text-blue-600 rounded border-gray-300"
              />
              <span className="text-sm font-medium text-gray-700">
                Multi-day event
              </span>
            </label>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {formData.isMultiDay ? 'Start Date' : 'Date'}
              </label>
              <div className="relative">
                <CalendarIcon className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
                <Input
                  type="date"
                  name="startDate"
                  required
                  value={formData.startDate}
                  onChange={handleInputChange}
                  className="pl-10"
                />
              </div>
            </div>
            {formData.isMultiDay ? (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  End Date
                </label>
                <div className="relative">
                  <CalendarIcon className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
                  <Input
                    type="date"
                    name="endDate"
                    required
                    value={formData.endDate}
                    onChange={handleInputChange}
                    className="pl-10"
                    min={formData.startDate}
                  />
                </div>
              </div>
            ) : (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Time
                </label>
                <div className="relative">
                  <Clock className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
                  <Input
                    type="time"
                    name="time"
                    value={formData.time}
                    onChange={handleInputChange}
                    className="pl-10"
                  />
                </div>
              </div>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Event Type
            </label>
            <select
              name="type"
              value={formData.type}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md text-sm bg-white"
            >
              <option value="meeting">Meeting</option>
              <option value="conference">Conference</option>
              <option value="webinar">Webinar</option>
              <option value="deadline">Deadline</option>
              <option value="review">Review</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Location (optional)
            </label>
            <Input
              type="text"
              name="location"
              value={formData.location}
              onChange={handleInputChange}
              className="w-full"
              placeholder="Add location"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description (optional)
            </label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              rows={3}
              className="w-full px-3 py-2 border rounded-md text-sm"
              placeholder="Add event description..."
            />
          </div>

          <div className="flex justify-end space-x-3">
            <Button
              type="button"
              variant="outline"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  Saving...
                </>
              ) : (
                eventToEdit ? 'Save Changes' : 'Create Event'
              )}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddEventDialog;
