import React, { useState, useEffect, useContext } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useHistory } from "react-router-dom";
import {
  Calendar,
  Search,
  Filter,
  ChevronDown,
  Calendar as CalendarIcon,
  Clock,
  Users,
  Plus,
  ChevronRight,
  ChevronUp,
  Mail,
  CheckCircle,
  XCircle,
  Edit,
  Trash2,
  Bell,
} from "lucide-react";
import { Card, CardHeader, CardContent } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import TeamCalendarModal from "../components/TeamCalendarModal";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../shared/components/UIElements/alert-dialog";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";

const getEventColor = (type) => {
  switch (type) {
    case "meeting":
      return "text-blue-600 bg-blue-50 border-blue-200";
    case "deadline":
      return "text-purple-600 bg-purple-50 border-purple-200";
    case "conference":
      return "text-green-600 bg-green-50 border-green-200";
    case "webinar":
      return "text-orange-600 bg-orange-50 border-orange-200";
    case "review":
      return "text-pink-600 bg-pink-50 border-pink-200";
    default:
      return "text-gray-600 bg-gray-50 border-gray-200";
  }
};

const EventCard = ({
  event,
  teamMembers,
  onDelete,
  onUpdate,
  isCurrentUser,
  auth,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showRSVPDialog, setShowRSVPDialog] = useState(false);
  const [showLeadDialog, setShowLeadDialog] = useState(false);
  const [rsvpStatus, setRsvpStatus] = useState(event.rsvpStatus || "pending");
  const [eventLeads, setEventLeads] = useState([]);
  const history = useHistory();
  const { sendRequest } = useHttpClient();

  useEffect(() => {
    const fetchEventLeads = async () => {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_API_URL}/events/${event.event_id}/leads`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          },
        );
        setEventLeads(response);
      } catch (err) {
        console.error("Error fetching event leads:", err);
      }
    };

    if (event.event_id) {
      fetchEventLeads();
    }
  }, [event.event_id, auth?.token, sendRequest]);

  const formatEventDate = (startDate, endDate, time) => {
    const start = new Date(startDate);
    const end = endDate ? new Date(endDate) : start;

    if (startDate === endDate || !endDate) {
      return `${start.toLocaleDateString()} ${time ? `at ${time}` : ""}`;
    }

    return `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`;
  };

  const handleRSVP = (status) => {
    setRsvpStatus(status);
    setShowRSVPDialog(false);
    // need to create event rsvp
  };

  const handleDelete = () => {
    setShowDeleteDialog(false);
    onDelete(event.event_id);
  };

  return (
    <>
      <Card className="overflow-hidden">
        <div className="p-6">
          <div className="flex items-start">
            <div
              className={`flex-shrink-0 w-12 h-12 rounded-lg ${getEventColor(event.type)} flex items-center justify-center mr-4`}
            >
              <CalendarIcon className="h-6 w-6" />
            </div>
            <div className="flex-1 min-w-0">
              <div className="flex items-start justify-between">
                <div className="flex-1">
                  <div className="flex items-center justify-between">
                    <h3 className="text-lg font-medium text-gray-900">
                      {event.title}
                    </h3>
                    <button
                      onClick={() => setIsExpanded(!isExpanded)}
                      className="ml-4 p-1 hover:bg-gray-100 rounded-full"
                    >
                      {isExpanded ? (
                        <ChevronUp className="h-5 w-5 text-gray-500" />
                      ) : (
                        <ChevronRight className="h-5 w-5 text-gray-500" />
                      )}
                    </button>
                  </div>
                  <div className="mt-1 flex items-center text-sm text-gray-500 space-x-4">
                    <div className="flex items-center">
                      <Clock className="h-4 w-4 mr-1" />
                      <span>
                        {formatEventDate(
                          event.startDate,
                          event.endDate,
                          event.time,
                        )}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <Users className="h-4 w-4 mr-1" />
                      <span>{event.attendeeNames.length} attendees</span>
                    </div>
                  </div>
                </div>
                <span
                  className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize ${getEventColor(event.type)}`}
                >
                  {event.type}
                </span>
              </div>

              {isExpanded && (
                <div className="mt-4 space-y-4">
                  {event.description && (
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <h4 className="text-sm font-medium text-gray-900 mb-2">
                        Description
                      </h4>
                      <p className="text-sm text-gray-600">
                        {event.description}
                      </p>
                    </div>
                  )}

                  <div className="border-t pt-4">
                    <h4 className="text-sm font-medium text-gray-900 mb-2">
                      Attendees
                    </h4>
                    <div className="grid grid-cols-2 gap-4">
                      {event.attendeeNames.map((attendee, index) => (
                        <div
                          key={index}
                          className="flex items-center space-x-2"
                        >
                          <div className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center">
                            <span className="text-xs font-medium text-gray-600">
                              {attendee.charAt(0)}
                            </span>
                          </div>
                          <div>
                            <p className="text-sm font-medium text-gray-900">
                              {attendee}
                            </p>
                            <p className="text-xs text-gray-500">
                              {index === 0 ? "Organizer" : "Attendee"}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {eventLeads.length > 0 && (
                    <div className="border-t pt-4">
                      <h4 className="text-sm font-medium text-gray-900 mb-2">
                        Captured Leads
                      </h4>
                      <div className="grid grid-cols-2 gap-4">
                        {eventLeads.map((lead, index) => (
                          <div
                            key={index}
                            className="flex items-center space-x-2 bg-gray-50 p-3 rounded-lg"
                          >
                            <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center">
                              <Users className="h-4 w-4 text-blue-600" />
                            </div>
                            <div>
                              <p className="text-sm font-medium text-gray-900">
                                {lead.contact_first_name}{" "}
                                {lead.contact_last_name}
                              </p>
                              <p className="text-xs text-gray-500">
                                {lead.business_name}
                              </p>
                              <p className="text-xs text-gray-500">
                                {lead.contact_job_title}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="border-t pt-4 flex justify-between items-center">
                    <div className="space-x-2">
                      <div className="flex space-x-2">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setShowRSVPDialog(true)}
                        >
                          {rsvpStatus === "pending" ? (
                            <>
                              <Bell className="h-4 w-4 mr-2" />
                              RSVP
                            </>
                          ) : rsvpStatus === "accepted" ? (
                            <>
                              <CheckCircle className="h-4 w-4 mr-2 text-green-500" />
                              Going
                            </>
                          ) : (
                            <>
                              <XCircle className="h-4 w-4 mr-2 text-red-500" />
                              Not Going
                            </>
                          )}
                        </Button>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setShowLeadDialog(true)}
                        >
                          <Plus className="h-4 w-4 mr-2" />
                          Add Leads
                        </Button>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => {
                            history.push(
                              `/leads/uploadlist?eventName=${encodeURIComponent(event.title)}&eventId=${encodeURIComponent(event.event_id)}`,
                            );
                          }}
                        >
                          <Plus className="h-4 w-4 mr-2" />
                          Upload List
                        </Button>
                        {isCurrentUser && (
                          <>
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => onUpdate(event)}
                            >
                              <Edit className="h-4 w-4 mr-2" />
                              Edit
                            </Button>
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => setShowDeleteDialog(true)}
                            >
                              <Trash2 className="h-4 w-4 mr-2" />
                              Delete
                            </Button>
                          </>
                        )}
                      </div>
                      {event.location && (
                        <div className="text-sm text-gray-500">
                          <span>Location: {event.location}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>

      {/* Delete Confirmation Dialog */}
      <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Event</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this event? This action cannot be
              undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              className="bg-red-600 hover:bg-red-700"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* RSVP Dialog */}
      <AlertDialog open={showRSVPDialog} onOpenChange={setShowRSVPDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>RSVP to Event</AlertDialogTitle>
            <AlertDialogDescription>
              Will you be attending this event?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => handleRSVP("declined")}>
              No, I can't make it
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => handleRSVP("accepted")}
              className="bg-green-600 hover:bg-green-700"
            >
              Yes, I'll be there
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* Lead Capture Dialog */}
      <Dialog open={showLeadDialog} onOpenChange={setShowLeadDialog}>
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle>Add Event Leads</DialogTitle>
            <DialogDescription>
              Add leads captured during the event
            </DialogDescription>
          </DialogHeader>

          <form
            onSubmit={async (e) => {
              e.preventDefault();
              const formData = new FormData(e.target);

              try {
                const response = await sendRequest(
                  `${process.env.REACT_APP_API_URL}/events/${event.event_id}/leads`,
                  "POST",
                  JSON.stringify({
                    contact_first_name: formData.get("firstName"),
                    contact_last_name: formData.get("lastName"),
                    contact_job_title: formData.get("jobTitle"),
                    contact_phone: formData.get("phone"),
                    contact_email: formData.get("email"),
                    business_name: formData.get("company"),
                    website: formData.get("website"),
                    event_id: event.event_id,
                    event_name: event.title,
                    lead_source: "Event",
                    created_by: auth.user_id,
                    created_date: new Date().toISOString(),
                  }),
                  {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth.token}`,
                  },
                );

                const newLead = response;
                setEventLeads([...eventLeads, newLead]);
                setShowLeadDialog(false);
                // Clear form
                e.target.reset();
              } catch (err) {
                console.error("Error adding event lead:", err);
                // may want to show an error message to the user here
              }
            }}
            className="space-y-4"
          >
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="text-sm font-medium">First Name</label>
                <Input required name="firstName" />
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium">Last Name</label>
                <Input required name="lastName" />
              </div>
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">Job Title</label>
              <Input required name="jobTitle" />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">Company</label>
              <Input required name="company" />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">Website</label>
              <Input required name="website" />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="text-sm font-medium">Phone</label>
                <Input required name="phone" type="tel" />
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium">Email</label>
                <Input required name="email" type="email" />
              </div>
            </div>

            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={() => setShowLeadDialog(false)}
              >
                Cancel
              </Button>
              <Button type="submit">Add Lead</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const TeamEvents = ({ teamMembers }) => {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterType, setFilterType] = useState("all");
  const [filterDate, setFilterDate] = useState("all"); // 'all', 'today', 'week', 'month'
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDateDropdown, setShowDateDropdown] = useState(false);
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchEvents = async () => {
      if (!auth?.token || !teamMembers) return;

      try {
        const eventPromises = teamMembers.map((member) =>
          sendRequest(
            `${process.env.REACT_APP_API_URL}/events/user/${member.user_id}`,
            "GET",
            null,
            {
              Authorization: `Bearer ${auth.token}`,
            },
          ).catch((err) => {
            console.error(
              `Error fetching events for user ${member.user_id}:`,
              err,
            );
            return [];
          }),
        );

        const allUserEvents = await Promise.all(eventPromises);
        const eventMap = new Map();

        const processedEvents = allUserEvents.flat().map((event) => ({
          ...event,
          ownerName: getMemberName(event.created_by),
          attendeeNames: (event.attendees || [])
            .map((attendeeId) => getMemberName(attendeeId))
            .filter((name) => name !== "Unknown User"),
        }));

        setEvents(processedEvents);
        setFilteredEvents(processedEvents);
      } catch (err) {
        console.error("Error fetching events:", err);
      } finally {
        setIsLoading(false);
      }
    };

    if (teamMembers?.length > 0 && auth.token) {
      fetchEvents();
    }
  }, [teamMembers, auth.token, sendRequest]);

  const getMemberName = (userId) => {
    const member = teamMembers.find((m) => m.user_id === userId);
    return member ? member.username : "Unknown User";
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/events/${eventId}`,
        "DELETE",
        null,
        {
          Authorization: `Bearer ${auth.token}`,
        },
      );
      setEvents(events.filter((event) => event.event_id !== eventId));
      setFilteredEvents(
        filteredEvents.filter((event) => event.event_id !== eventId),
      );
    } catch (err) {
      console.error("Error deleting event:", err);
    }
  };

  const handleUpdateEvent = (event) => {
    setIsCalendarOpen(true);
    // Pass the event data to the calendar modal for editing
  };

  useEffect(() => {
    let filtered = [...events];

    // Apply search filter
    if (searchTerm) {
      filtered = filtered.filter(
        (event) =>
          event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          event.ownerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          event.attendeeNames.some((name) =>
            name.toLowerCase().includes(searchTerm.toLowerCase()),
          ),
      );
    }

    // Apply type filter
    if (filterType !== "all") {
      filtered = filtered.filter((event) => event.type === filterType);
    }

    // Apply date filter
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const weekFromNow = new Date(today);
    weekFromNow.setDate(weekFromNow.getDate() + 7);
    const monthFromNow = new Date(today);
    monthFromNow.setMonth(monthFromNow.getMonth() + 1);

    if (filterDate === "today") {
      filtered = filtered.filter((event) => {
        const eventDate = new Date(event.startDate);
        return (
          eventDate >= today &&
          eventDate < new Date(today.getTime() + 24 * 60 * 60 * 1000)
        );
      });
    } else if (filterDate === "week") {
      filtered = filtered.filter((event) => {
        const eventDate = new Date(event.startDate);
        return eventDate >= today && eventDate <= weekFromNow;
      });
    } else if (filterDate === "month") {
      filtered = filtered.filter((event) => {
        const eventDate = new Date(event.startDate);
        return eventDate >= today && eventDate <= monthFromNow;
      });
    }

    setFilteredEvents(filtered);
  }, [searchTerm, filterType, filterDate, events]);

  if (isLoading) {
    return (
      <div className="p-8 pb-20">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 rounded w-1/4"></div>
          <div className="space-y-3">
            {[1, 2, 3].map((i) => (
              <div key={i} className="h-32 bg-gray-200 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-8 pb-20">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">Team Events</h1>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setIsCalendarOpen(true)}
            className="inline-flex items-center gap-2 px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-lg hover:bg-blue-700 transition-colors"
          >
            <Plus className="h-4 w-4" />
            Add Event
          </button>
        </div>
      </div>

      <div className="mb-6 flex gap-4">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
          <Input
            type="text"
            placeholder="Search events..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10"
          />
        </div>

        {/* Type Filter */}
        <div className="relative">
          <button
            onClick={() => setShowDropdown(!showDropdown)}
            className="inline-flex items-center gap-2 px-4 py-2 border rounded-lg text-sm text-gray-700 hover:bg-gray-50"
          >
            <Filter className="h-4 w-4" />
            Filter by type
            <ChevronDown className="h-4 w-4" />
          </button>
          {showDropdown && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border z-10">
              <div className="py-1">
                {[
                  "all",
                  "meeting",
                  "deadline",
                  "conference",
                  "webinar",
                  "review",
                ].map((type) => (
                  <button
                    key={type}
                    onClick={() => {
                      setFilterType(type);
                      setShowDropdown(false);
                    }}
                    className={`block w-full text-left px-4 py-2 text-sm ${
                      filterType === type ? "bg-gray-100" : "hover:bg-gray-50"
                    }`}
                  >
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Date Filter */}
        <div className="relative">
          <button
            onClick={() => setShowDateDropdown(!showDateDropdown)}
            className="inline-flex items-center gap-2 px-4 py-2 border rounded-lg text-sm text-gray-700 hover:bg-gray-50"
          >
            <Calendar className="h-4 w-4" />
            {filterDate === "all"
              ? "All dates"
              : filterDate === "today"
                ? "Today"
                : filterDate === "week"
                  ? "This week"
                  : "This month"}
            <ChevronDown className="h-4 w-4" />
          </button>
          {showDateDropdown && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border z-10">
              <div className="py-1">
                {["all", "today", "week", "month"].map((date) => (
                  <button
                    key={date}
                    onClick={() => {
                      setFilterDate(date);
                      setShowDateDropdown(false);
                    }}
                    className={`block w-full text-left px-4 py-2 text-sm ${
                      filterDate === date ? "bg-gray-100" : "hover:bg-gray-50"
                    }`}
                  >
                    {date === "all"
                      ? "All dates"
                      : date === "today"
                        ? "Today"
                        : date === "week"
                          ? "This week"
                          : "This month"}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="space-y-4">
        {filteredEvents.map((event) => (
          <EventCard
            key={event.event_id}
            event={event}
            teamMembers={teamMembers}
            onDelete={handleDeleteEvent}
            onUpdate={handleUpdateEvent}
            isCurrentUser={event.created_by === auth.user_id}
            auth={auth}
          />
        ))}

        {filteredEvents.length === 0 && (
          <div className="text-center py-12">
            <CalendarIcon className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              No events found
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {searchTerm || filterType !== "all" || filterDate !== "all"
                ? "Try adjusting your search or filter settings"
                : "Get started by creating a new event"}
            </p>
          </div>
        )}
      </div>

      <TeamCalendarModal
        isOpen={isCalendarOpen}
        onClose={() => setIsCalendarOpen(false)}
        teamMembers={teamMembers}
        auth={auth}
        onEventAdded={() => {
          // Refresh events after adding a new one
          window.location.reload();
        }}
      />
    </div>
  );
};

export default TeamEvents;
