import React, { useState, useEffect, useContext, useCallback } from "react";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import {
  Save,
  Plus,
  Filter,
  X,
  ChevronDown,
  ChevronUp,
  Building,
  Users,
  MapPin,
  Briefcase,
  TrendingUp,
  Copy,
  Download,
  History,
  RotateCcw,
  HelpCircle,
  Search,
  BarChart2,
  Trash2,
  AlertCircle,
  Loader2,
} from "lucide-react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../user/components/ui/card";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../shared/components/UIElements/accordion";
import { Button } from "../../teamhub/components/ui/button";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../teamhub/components/ui/alert";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../shared/components/UIElements/alert-dialog";
import { Badge } from "../../user/components/ui/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from "../../user/components/ui/tooltip";
import { Progress } from "../../user/components/ui/Progress";
import { Skeleton } from "../../shared/components/UIElements/skeleton";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../teamhub/components/ui/tabs";
import TutorialWalkthrough from "../components/TutorialWalkthrough";
import TopCompaniesCard from "../components/TopCompaniesCard";
import AnalyticsCharts from "../components/AnalyticsCharts";
import GeoDistribution from "../components/GeoDistribution";
import AudienceSummary from "../components/AudienceSummary";
import AudienceDataProvider, {
  useAudienceData,
} from "../components/AudienceDataProvider";

const AudienceBuilder = () => {
  const auth = useContext(AuthContext);
  const {
    isLoading: httpLoading,
    error,
    sendRequest,
    clearError,
  } = useHttpClient();
  const [savedAudiences, setSavedAudiences] = useState([]);
  const [audienceName, setAudienceName] = useState("");
  const [selectedAudience, setSelectedAudience] = useState(null);
  const [selectedJobLevels, setSelectedJobLevels] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedCompanySizes, setSelectedCompanySizes] = useState([]);
  const [selectedRevenueRanges, setSelectedRevenueRanges] = useState([]);
  const [selectedVerticals, setSelectedVerticals] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedHQDivisions, setSelectedHQDivisions] = useState([]);
  const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);
  const [selectedTopicClusters, setSelectedTopicClusters] = useState([]);
  const [excludedVerticals, setExcludedVerticals] = useState([]);
  const [excludedDepartments, setExcludedDepartments] = useState([]);
  const [excludedJobFunctions, setExcludedJobFunctions] = useState([]);
  const [saveStatus, setSaveStatus] = useState({ success: false, message: "" });
  const [isComponentLoading, setIsComponentLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [showTutorial, setShowTutorial] = useState(false);
  const [audienceAnalytics, setAudienceAnalytics] = useState({
    demographics: {},
    geography: {},
  });

  const [audienceSummary, setAudienceSummary] = useState({
    companies: 0,
    contacts: 0,
    workEmails: 0,
    personalEmails: 0,
    directPhones: 0,
    mobilePhones: 0,
    linkedinProfiles: 0,
    addresses: 0,
  });

  const jobLevelOptions = [
    { value: "director", label: "Director" },
    { value: "owner", label: "Owner" },
    { value: "vp", label: "VP" },
    { value: "cxo", label: "CXO" },
    { value: "manager", label: "Manager" },
    { value: "other", label: "Other" },
  ];

  const companySizeOptions = [
    { value: "1-9", label: "Micro (1-9)" },
    { value: "10-49", label: "Small (10-49)" },
    { value: "50-199", label: "Medium-Small (50-199)" },
    { value: "200-499", label: "Medium (201-499)" },
    { value: "500-999", label: "Medium-Large (500-999)" },
    { value: "1000+", label: "Large (1,000+)" },
  ];

  const revenueRangeOptions = [
    { value: "<1MM", label: "Micro (<$1MM)" },
    { value: "1MM-10MM", label: "Small ($1MM-$10MM)" },
    { value: "10MM-50MM", label: "Medium-Small ($10MM-$50MM)" },
    { value: "50MM-100MM", label: "Medum-Large ($50MM-$100MM)" },
    { value: "100MM-200MM", label: "Large ($100MM-$200MM)" },
    { value: "200MM-1B", label: "XLarge ($200MM-$1B)" },
    { value: "1B+", label: "XXLarge ($1B+)" },
  ];

  const verticalOptions = [
    { value: "agriculture", label: "Agriculture" },
    { value: "business-services", label: "Business Services" },
    { value: "education", label: "Education" },
    { value: "energy-utilities", label: "Energy and Utilities" },
    { value: "finance", label: "Finance" },
    { value: "government", label: "Government and Public Sector" },
    { value: "healthcare", label: "Healthcare" },
    { value: "hospitality", label: "Hospitality and Entertainment" },
    { value: "insurance", label: "Insurance" },
    { value: "manufacturing", label: "Manufacturing" },
    { value: "media-communications", label: "Media and Communications" },
    { value: "membership-organizations", label: "Membership Organizations" },
    { value: "mining-extraction", label: "Mining and Extraction" },
    { value: "non-profit", label: "Non-Profit and NGOs" },
    { value: "personal-services", label: "Personal Services" },
    { value: "quick-serve-restaurants", label: "Quick Serve Restaurants" },
    {
      value: "real-estate-construction",
      label: "Real Estate and Construction",
    },
    { value: "retail-ecommerce", label: "Retail and E-commerce" },
    { value: "technology", label: "Technology" },
    { value: "telecommunications", label: "Telecommunications" },
    {
      value: "transportation-logistics",
      label: "Transportation and Logistics",
    },
    { value: "wholesale-trade", label: "Wholesale Trade" },
  ];

  const departmentOptions = [
    { value: "executive-leadership", label: "Executive Leadership" },
    { value: "operations", label: "Operations" },
    { value: "engineering-technical", label: "Engineering & Technical" },
    { value: "finance", label: "Finance" },
    { value: "human-resources", label: "Human Resources" },
    { value: "marketing", label: "Marketing" },
    { value: "sales-business-dev", label: "Sales & Business Development" },
    { value: "customer-service", label: "Customer Service & Support" },
    { value: "legal-compliance", label: "Legal & Compliance" },
    { value: "product-management", label: "Product Management" },
    { value: "procurement", label: "Procurement & Purchasing" },
    { value: "facilities", label: "Facilities & Real Estate" },
    { value: "health-safety", label: "Health & Safety" },
  ];

  const locationOptions = [
    { value: "headquarter", label: "Headquarter" },
    { value: "other-location", label: "Other Location" },
  ];

  const hqDivisionOptions = [
    { value: "CENTRAL", label: "Central" },
    { value: "NORTHEAST", label: "Northeast" },
    { value: "WEST", label: "West" },
    { value: "OUT-OF-FOOTPRINT", label: "Out of Footprint" },
  ];

  const jobFunctionsByDepartment = [
    {
      department: "Executive Leadership",
      functions: [
        { value: "c-suite", label: "C-Suite" },
        { value: "board-of-directors", label: "Board of Directors" },
        {
          value: "strategy-corp-dev",
          label: "Strategy & Corporate Development",
        },
      ],
    },
    {
      department: "Operations",
      functions: [
        { value: "operations-management", label: "Operations Management" },
        { value: "supply-chain-logistics", label: "Supply Chain & Logistics" },
        { value: "quality-assurance", label: "Quality Assurance" },
      ],
    },
    {
      department: "Engineering & Technical",
      functions: [
        { value: "engineering", label: "Engineering" },
        { value: "software-development", label: "Software Development" },
        { value: "it-technical-support", label: "IT & Technical Support" },
        { value: "research-development", label: "Research & Development" },
        { value: "data-science-analytics", label: "Data Science & Analytics" },
      ],
    },
    {
      department: "Finance",
      functions: [
        {
          value: "financial-planning-analysis",
          label: "Financial Planning & Analysis",
        },
        { value: "accounting", label: "Accounting" },
        { value: "audit-compliance", label: "Audit & Compliance" },
        { value: "investor-relations", label: "Investor Relations" },
      ],
    },
    {
      department: "Human Resources",
      functions: [
        {
          value: "talent-acquisition",
          label: "Talent Acquisition & Recruitment",
        },
        { value: "employee-relations", label: "Employee Relations" },
        { value: "learning-development", label: "Learning & Development" },
        { value: "compensation-benefits", label: "Compensation & Benefits" },
      ],
    },
    {
      department: "Marketing",
      functions: [
        { value: "brand-communications", label: "Brand & Communications" },
        { value: "product-marketing", label: "Product Marketing" },
        { value: "digital-marketing", label: "Digital Marketing" },
        { value: "content-creative", label: "Content & Creative Services" },
      ],
    },
    {
      department: "Sales & Business Development",
      functions: [
        { value: "sales-operations", label: "Sales Operations" },
        { value: "business-development", label: "Business Development" },
        { value: "account-management", label: "Account Management" },
        { value: "customer-success", label: "Customer Success" },
      ],
    },
    {
      department: "Customer Service & Support",
      functions: [
        { value: "customer-service", label: "Customer Service" },
        { value: "technical-support", label: "Technical Support" },
        {
          value: "client-success-management",
          label: "Client Success Management",
        },
      ],
    },
    {
      department: "Legal & Compliance",
      functions: [
        { value: "legal-department", label: "Legal Department" },
        {
          value: "compliance-regulatory",
          label: "Compliance & Regulatory Affairs",
        },
        { value: "risk-management", label: "Risk Management" },
        {
          value: "intellectual-property",
          label: "Intellectual Property Management",
        },
      ],
    },
    {
      department: "Product Management",
      functions: [
        { value: "product-development", label: "Product Development" },
        { value: "product-strategy", label: "Product Strategy & Roadmap" },
        { value: "ux-design", label: "User Experience (UX) & Design" },
      ],
    },
    {
      department: "Procurement & Purchasing",
      functions: [
        { value: "vendor-management", label: "Vendor Management" },
        { value: "sourcing", label: "Sourcing" },
        { value: "contract-management", label: "Contract Management" },
      ],
    },
    {
      department: "Facilities & Real Estate",
      functions: [
        { value: "facilities-management", label: "Facilities Management" },
        {
          value: "real-estate-property",
          label: "Real Estate & Property Management",
        },
        { value: "workplace-services", label: "Workplace Services" },
      ],
    },
    {
      department: "Health & Safety",
      functions: [
        { value: "ehs", label: "Environmental Health & Safety (EHS)" },
        { value: "occupational-health", label: "Occupational Health" },
        { value: "security", label: "Security" },
      ],
    },
  ];

  const topicClusterOptions = [
    { value: "security", label: "Security" },
    { value: "internet", label: "Internet" },
    { value: "sd-wan", label: "SD-WAN" },
    { value: "wireless", label: "Wireless" },
    { value: "voip-telephony", label: "VoIP and Telephony" },
    { value: "vpn", label: "VPN" },
    { value: "managed-services", label: "Managed Services" },
    { value: "competitors", label: "Competitors" },
    { value: "metro-e", label: "Metro-E" },
    { value: "mobile", label: "Mobile" },
    { value: "movers", label: "Movers" },
  ];

  const handleJobLevelSelect = (event) => {
    const value = event.target.value;
    if (!selectedJobLevels.includes(value)) {
      setSelectedJobLevels([...selectedJobLevels, value]);
    }
    // Reset the select value
    event.target.value = "";
  };

  const removeJobLevel = (levelToRemove) => {
    setSelectedJobLevels(
      selectedJobLevels.filter((level) => level !== levelToRemove),
    );
  };

  const handleCompanySizeSelect = (event) => {
    const value = event.target.value;
    if (!selectedCompanySizes.includes(value)) {
      setSelectedCompanySizes([...selectedCompanySizes, value]);
    }
    event.target.value = "";
  };

  const handleRevenueRangeSelect = (event) => {
    const value = event.target.value;
    if (!selectedRevenueRanges.includes(value)) {
      setSelectedRevenueRanges([...selectedRevenueRanges, value]);
    }
    event.target.value = "";
  };

  const handleVerticalSelect = (event) => {
    const value = event.target.value;
    if (!selectedVerticals.includes(value)) {
      setSelectedVerticals([...selectedVerticals, value]);
    }
    event.target.value = "";
  };

  const removeCompanySize = (sizeToRemove) => {
    setSelectedCompanySizes(
      selectedCompanySizes.filter((size) => size !== sizeToRemove),
    );
  };

  const removeRevenueRange = (rangeToRemove) => {
    setSelectedRevenueRanges(
      selectedRevenueRanges.filter((range) => range !== rangeToRemove),
    );
  };

  const removeVertical = (verticalToRemove) => {
    setSelectedVerticals(
      selectedVerticals.filter((vertical) => vertical !== verticalToRemove),
    );
  };

  const handleDepartmentSelect = (event) => {
    const value = event.target.value;
    if (!selectedDepartments.includes(value)) {
      setSelectedDepartments([...selectedDepartments, value]);
    }
    event.target.value = "";
  };

  const removeDepartment = (departmentToRemove) => {
    setSelectedDepartments(
      selectedDepartments.filter((dept) => dept !== departmentToRemove),
    );
  };

  const handleLocationSelect = (event) => {
    const value = event.target.value;
    if (!selectedLocations.includes(value)) {
      setSelectedLocations([...selectedLocations, value]);
    }
    event.target.value = "";
  };

  const handleHQDivisionSelect = (event) => {
    const value = event.target.value;
    if (!selectedHQDivisions.includes(value)) {
      setSelectedHQDivisions([...selectedHQDivisions, value]);
    }
    event.target.value = "";
  };

  const removeLocation = (locationToRemove) => {
    setSelectedLocations(
      selectedLocations.filter((loc) => loc !== locationToRemove),
    );
  };

  const removeHQDivision = (divisionToRemove) => {
    setSelectedHQDivisions(
      selectedHQDivisions.filter((div) => div !== divisionToRemove),
    );
  };

  const handleJobFunctionSelect = (event) => {
    const value = event.target.value;
    if (!selectedJobFunctions.includes(value)) {
      setSelectedJobFunctions([...selectedJobFunctions, value]);
    }
    event.target.value = "";
  };

  const removeJobFunction = (functionToRemove) => {
    setSelectedJobFunctions(
      selectedJobFunctions.filter((func) => func !== functionToRemove),
    );
  };

  const handleExcludedVerticalSelect = (event) => {
    const value = event.target.value;
    if (!excludedVerticals.includes(value)) {
      setExcludedVerticals([...excludedVerticals, value]);
    }
    event.target.value = "";
  };

  const handleExcludedDepartmentSelect = (event) => {
    const value = event.target.value;
    if (!excludedDepartments.includes(value)) {
      setExcludedDepartments([...excludedDepartments, value]);
    }
    event.target.value = "";
  };

  const handleExcludedJobFunctionSelect = (event) => {
    const value = event.target.value;
    if (!excludedJobFunctions.includes(value)) {
      setExcludedJobFunctions([...excludedJobFunctions, value]);
    }
    event.target.value = "";
  };

  const removeExcludedVertical = (verticalToRemove) => {
    setExcludedVerticals(
      excludedVerticals.filter((vertical) => vertical !== verticalToRemove),
    );
  };

  const removeExcludedDepartment = (departmentToRemove) => {
    setExcludedDepartments(
      excludedDepartments.filter((dept) => dept !== departmentToRemove),
    );
  };

  const removeExcludedJobFunction = (functionToRemove) => {
    setExcludedJobFunctions(
      excludedJobFunctions.filter((func) => func !== functionToRemove),
    );
  };

  const handleTopicClusterSelect = (event) => {
    const value = event.target.value;
    if (!selectedTopicClusters.includes(value)) {
      setSelectedTopicClusters([...selectedTopicClusters, value]);
    }
    event.target.value = "";
  };

  const removeTopicCluster = (clusterToRemove) => {
    setSelectedTopicClusters(
      selectedTopicClusters.filter((cluster) => cluster !== clusterToRemove),
    );
  };

  const handleDeleteAudience = async () => {
    if (!selectedAudience) {
      setSaveStatus({
        success: false,
        message: "No audience selected to delete",
      });
      return;
    }

    try {
      const url = new URL(
        `${process.env.REACT_APP_API_URL}/audiences/${selectedAudience}`,
      );

      await sendRequest(url.toString(), "DELETE", null, {
        Authorization: `Bearer ${auth.token}`,
      });

      // Clear the selected audience and filters
      setSelectedAudience(null);
      clearAllFilters();
      setAudienceName("");

      // Refresh the saved audiences list
      await loadSavedAudiences();

      setSaveStatus({
        success: true,
        message: "Audience deleted successfully",
      });

      // Reset status after 3 seconds
      setTimeout(() => {
        setSaveStatus({ success: false, message: "" });
      }, 3000);
    } catch (err) {
      setSaveStatus({
        success: false,
        message: "Failed to delete audience",
      });
    }
  };

  const handleDuplicateAudience = () => {
    if (selectedAudience) {
      const selectedAudienceData = savedAudiences.find(
        (audience) => audience.audience_id === selectedAudience,
      );

      if (selectedAudienceData) {
        // Clear existing filters
        clearAllFilters();

        // Set the new audience name with "Copy" suffix
        setAudienceName(`${selectedAudienceData.name} - Copy`);

        // Apply the filters from the selected audience
        const filters = selectedAudienceData.filters;

        // Set included filters
        setSelectedJobLevels(filters.jobLevels || []);
        setSelectedDepartments(filters.departments || []);
        setSelectedCompanySizes(filters.companySizes || []);
        setSelectedRevenueRanges(filters.revenueRanges || []);
        setSelectedVerticals(filters.verticals || []);
        setSelectedLocations(filters.locations || []);
        setSelectedHQDivisions(filters.hqDivisions || []);
        setSelectedJobFunctions(filters.jobFunctions || []);
        setSelectedTopicClusters(filters.topicClusters || []);

        // Set excluded filters
        if (filters.exclusions) {
          setExcludedVerticals(filters.exclusions.verticals || []);
          setExcludedDepartments(filters.exclusions.departments || []);
          setExcludedJobFunctions(filters.exclusions.jobFunctions || []);
        }
      }
    }
  };

  const handleExportAudience = () => {
    const exportData = {
      name: audienceName,
      filters: {
        jobLevels: selectedJobLevels,
        departments: selectedDepartments,
        companySizes: selectedCompanySizes,
        revenueRanges: selectedRevenueRanges,
        verticals: selectedVerticals,
        locations: selectedLocations,
        hqDivisions: selectedHQDivisions,
        jobFunctions: selectedJobFunctions,
        topicClusters: selectedTopicClusters,
        exclusions: {
          verticals: excludedVerticals,
          departments: excludedDepartments,
          jobFunctions: excludedJobFunctions,
        },
      },
      summary: audienceSummary,
    };

    const blob = new Blob([JSON.stringify(exportData, null, 2)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${audienceName.toLowerCase().replace(/\s+/g, "-")}-export.json`;
    a.click();
  };

  const getFilterWarnings = useCallback(() => {
    const warnings = [];

    // Check if at least one filter is selected
    const hasAnyFilter =
      selectedJobLevels.length > 0 ||
      selectedDepartments.length > 0 ||
      selectedCompanySizes.length > 0 ||
      selectedRevenueRanges.length > 0 ||
      selectedVerticals.length > 0 ||
      selectedLocations.length > 0 ||
      selectedHQDivisions.length > 0 ||
      selectedJobFunctions.length > 0 ||
      selectedTopicClusters.length > 0;

    if (!hasAnyFilter) {
      warnings.push("Please select at least one filter to build your audience");
    }

    return warnings;
  }, [
    selectedJobLevels,
    selectedDepartments,
    selectedCompanySizes,
    selectedRevenueRanges,
    selectedVerticals,
    selectedLocations,
    selectedHQDivisions,
    selectedJobFunctions,
    selectedTopicClusters,
  ]);

  const handleSaveAudience = async () => {
    if (!audienceName.trim()) {
      setSaveStatus({
        success: false,
        message: "Please enter an audience name",
      });
      return;
    }

    try {
      const url = new URL(`${process.env.REACT_APP_API_URL}/audiences/save`);
      const payload = {
        name: audienceName,
        filters: {
          job_levels: selectedJobLevels,
          departments: selectedDepartments,
          company_sizes: selectedCompanySizes,
          revenue_ranges: selectedRevenueRanges,
          verticals: selectedVerticals,
          locations: selectedLocations,
          hq_divisions: selectedHQDivisions,
          job_functions: selectedJobFunctions,
          topic_clusters: selectedTopicClusters,
          exclusions: {
            verticals: excludedVerticals,
            departments: excludedDepartments,
            job_functions: excludedJobFunctions,
          },
        },
      };

      const response = await sendRequest(
        url.toString(),
        "POST",
        JSON.stringify(payload),
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      );

      // Refresh the saved audiences list
      await loadSavedAudiences();

      setSaveStatus({
        success: true,
        message: "Audience saved successfully",
      });

      // Set the newly created audience as selected
      if (response && response.audience_id) {
        setSelectedAudience(response.audience_id);
      }

      // Reset status after 3 seconds
      setTimeout(() => {
        setSaveStatus({ success: false, message: "" });
      }, 3000);
    } catch (err) {
      setSaveStatus({
        success: false,
        message: err.message || "Failed to save audience",
      });
    }
  };

  const loadSavedAudiences = async () => {
    setIsComponentLoading(true);
    try {
      const url = new URL(
        `${process.env.REACT_APP_API_URL}/audiences/user/${auth.user_id}`,
      );
      const responseData = await sendRequest(url.toString(), "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      });
      setSavedAudiences(responseData);
    } catch (err) {
      console.error("Error loading audiences:", err);
    }
    setIsComponentLoading(false);
  };

  useEffect(() => {
    loadSavedAudiences();
  }, []);

  const currentFilters = {
    jobLevels: selectedJobLevels,
    departments: selectedDepartments,
    companySizes: selectedCompanySizes,
    revenueRanges: selectedRevenueRanges,
    verticals: selectedVerticals,
    locations: selectedLocations,
    hqDivisions: selectedHQDivisions,
    jobFunctions: selectedJobFunctions,
    topicClusters: selectedTopicClusters,
    exclusions: {
      verticals: excludedVerticals,
      departments: excludedDepartments,
      jobFunctions: excludedJobFunctions,
    },
  };

  const clearAllFilters = () => {
    setSelectedJobLevels([]);
    setSelectedDepartments([]);
    setSelectedCompanySizes([]);
    setSelectedRevenueRanges([]);
    setSelectedVerticals([]);
    setSelectedLocations([]);
    setSelectedHQDivisions([]);
    setSelectedJobFunctions([]);
    setSelectedTopicClusters([]);
    setExcludedVerticals([]);
    setExcludedDepartments([]);
    setExcludedJobFunctions([]);
  };

  const handleSavedAudienceSelect = (event) => {
    const audienceId = event.target.value;
    setSelectedAudience(audienceId);

    if (!audienceId) {
      clearAllFilters();
      setAudienceName("");
      return;
    }

    const selectedAudienceData = savedAudiences.find(
      (audience) => audience.audience_id === audienceId,
    );

    if (selectedAudienceData) {
      // Clear existing filters first
      clearAllFilters();

      // Set the audience name
      setAudienceName(selectedAudienceData.name);

      // Apply the saved filters
      const filters = selectedAudienceData.filters;

      // Set included filters
      setSelectedJobLevels(filters.jobLevels || []);
      setSelectedDepartments(filters.departments || []);
      setSelectedCompanySizes(filters.companySizes || []);
      setSelectedRevenueRanges(filters.revenueRanges || []);
      setSelectedVerticals(filters.verticals || []);
      setSelectedLocations(filters.locations || []);
      setSelectedHQDivisions(filters.hqDivisions || []);
      setSelectedJobFunctions(filters.jobFunctions || []);
      setSelectedTopicClusters(filters.topicClusters || []);

      // Set excluded filters
      if (filters.exclusions) {
        setExcludedVerticals(filters.exclusions.verticals || []);
        setExcludedDepartments(filters.exclusions.departments || []);
        setExcludedJobFunctions(filters.exclusions.jobFunctions || []);
      }
    }
  };

  const AudienceContent = () => {
    const { audienceData, isLoading, fetchAllData } = useAudienceData();

    const handleFetchAudience = async () => {
      const warnings = getFilterWarnings();
      if (warnings.length > 0) {
        console.warn("Warnings detected:", warnings);
        return;
      }

      try {
        const payload = {
          jobLevels: selectedJobLevels,
          departments: selectedDepartments,
          companySizes: selectedCompanySizes,
          revenueRanges: selectedRevenueRanges,
          verticals: selectedVerticals,
          locations: selectedLocations,
          hqDivisions: selectedHQDivisions,
          jobFunctions: selectedJobFunctions,
          topicClusters: selectedTopicClusters,
          exclusions: {
            verticals: excludedVerticals,
            departments: excludedDepartments,
            jobFunctions: excludedJobFunctions,
          },
        };

        console.log("Fetching audience with payload:", payload);
        await fetchAllData(payload);
        setIsComponentLoading(false);
      } catch (error) {
        console.error("Error fetching audience data:", error);
      }
    };

    return (
      <div id="audience-content">
        <Button
          onClick={handleFetchAudience}
          className="bg-purple-600 hover:bg-purple-700 text-white w-full mb-4"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              Fetching...
            </>
          ) : (
            <>
              <Search className="w-4 h-4 mr-2" />
              Fetch Audience
            </>
          )}
        </Button>

        <Tabs defaultValue="summary">
          <TabsList className="w-full">
            <TabsTrigger value="summary" className="flex-1">
              Summary
            </TabsTrigger>
            <TabsTrigger value="analytics" className="flex-1">
              Analytics
            </TabsTrigger>
          </TabsList>

          <TabsContent value="summary">
            <AudienceSummary data={audienceData.summary} />
            <TopCompaniesCard companies={audienceData.topCompanies} />
          </TabsContent>

          <TabsContent value="analytics">
            <AnalyticsCharts distributionData={audienceData.distribution} />
            <GeoDistribution geoData={audienceData.geoDistribution} />
          </TabsContent>
        </Tabs>
      </div>
    );
  };

  useEffect(() => {
    let completedSteps = 0;

    // Step 1: Audience Name
    if (audienceName.trim()) {
      completedSteps++;
    }

    // Step 2: Filters
    if (
      selectedJobLevels.length > 0 ||
      selectedDepartments.length > 0 ||
      selectedCompanySizes.length > 0 ||
      selectedVerticals.length > 0
    ) {
      completedSteps++;
    }

    // Step 3: Review (if they've set a name and filters)
    if (
      completedSteps === 2 &&
      (audienceSummary.contacts > 0 || audienceSummary.companies > 0)
    ) {
      completedSteps++;
    }

    setCurrentStep(completedSteps);
  }, [
    audienceName,
    selectedJobLevels,
    selectedDepartments,
    selectedCompanySizes,
    selectedVerticals,
    audienceSummary,
  ]);

  return (
    <TooltipProvider>
      <div className="min-h-screen bg-gray-50 p-6">
        <TutorialWalkthrough
          isOpen={showTutorial}
          onClose={() => setShowTutorial(false)}
        />

        {/* Progress Tracker */}
        <div className="max-w-[1600px] mx-auto mb-8">
          <div className="flex items-center justify-between mb-2">
            <h2 className="text-sm font-medium">Build Progress</h2>
            <span className="text-sm text-gray-500">
              {currentStep}/3 steps completed
            </span>
          </div>
          <Progress value={currentStep * 33.33} className="h-2" />
        </div>

        <div className="max-w-[1600px] mx-auto grid grid-cols-12 gap-6 pb-24">
          {/* Left Column - Filters */}
          <div className="col-span-5">
            <div className="space-y-6">
              {/* Audience Name Card with Enhanced Header */}
              <Card id="audience-name-section">
                <CardHeader>
                  <div className="flex justify-between items-center">
                    <div className="space-y-1">
                      <CardTitle>Audience Name</CardTitle>
                      {selectedAudience && (
                        <div className="text-sm text-gray-500">
                          Last modified: {new Date().toLocaleDateString()}
                        </div>
                      )}
                    </div>
                    <div className="flex items-center gap-2">
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="outline"
                            size="icon"
                            onClick={handleExportAudience}
                          >
                            <Download className="w-4 h-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>Export Audience</TooltipContent>
                      </Tooltip>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="outline"
                            size="icon"
                            onClick={() => setShowTutorial(true)}
                          >
                            <HelpCircle className="w-4 h-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>Show Tutorial</TooltipContent>
                      </Tooltip>
                      <Button
                        id="save-audience-button"
                        onClick={handleSaveAudience}
                        className="bg-purple-600 hover:bg-purple-700 text-white"
                      >
                        <Save className="w-4 h-4 mr-2" />
                        Save Audience
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <input
                      type="text"
                      placeholder="Enter audience name"
                      value={audienceName}
                      onChange={(e) => setAudienceName(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                    />
                    {saveStatus.message && (
                      <Alert
                        className={
                          saveStatus.success
                            ? "bg-green-50 text-green-700 border-green-200"
                            : "bg-red-50 text-red-700 border-red-200"
                        }
                      >
                        <AlertTitle>
                          {saveStatus.success ? "Success" : "Error"}
                        </AlertTitle>
                        <AlertDescription>
                          {saveStatus.message}
                        </AlertDescription>
                      </Alert>
                    )}
                  </div>
                </CardContent>
              </Card>

              {/* Saved Audiences */}
              <Card>
                <CardHeader>
                  <div className="flex justify-between items-center">
                    <CardTitle>Saved Audiences</CardTitle>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={handleDuplicateAudience}
                      disabled={!selectedAudience}
                    >
                      <Copy className="w-4 h-4 mr-2" />
                      Duplicate Selected
                    </Button>
                  </div>
                </CardHeader>
                <CardContent>
                  {isComponentLoading ? (
                    <div className="space-y-2">
                      <Skeleton className="h-10 w-full" />
                      <Skeleton className="h-10 w-full" />
                    </div>
                  ) : (
                    <>
                      <select
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                        onChange={handleSavedAudienceSelect}
                        value={selectedAudience || ""}
                      >
                        <option value="">Select a saved audience</option>
                        {savedAudiences.map((audience) => (
                          <option
                            key={audience.audience_id}
                            value={audience.audience_id}
                          >
                            {audience.name}
                          </option>
                        ))}
                      </select>
                      {selectedAudience && (
                        <div className="mt-4 flex justify-end">
                          <AlertDialog>
                            <AlertDialogTrigger asChild>
                              <Button variant="destructive" size="sm">
                                <Trash2 className="w-4 h-4 mr-2" />
                                Delete Audience
                              </Button>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                              <AlertDialogHeader>
                                <AlertDialogTitle>
                                  Delete Audience
                                </AlertDialogTitle>
                                <AlertDialogDescription>
                                  Are you sure you want to delete this audience?
                                  This action cannot be undone.
                                </AlertDialogDescription>
                              </AlertDialogHeader>
                              <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction
                                  onClick={handleDeleteAudience}
                                >
                                  Delete
                                </AlertDialogAction>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>
                        </div>
                      )}
                    </>
                  )}
                </CardContent>
              </Card>

              {/* Filters */}
              <Card id="filters-section">
                <CardHeader>
                  <div className="space-y-4">
                    {" "}
                    {/* Define Audience Title */}
                    <div className="flex justify-between items-center">
                      <CardTitle>Define Audience</CardTitle>
                      {/* Clear All Filters Button */}
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => {
                          setSelectedJobLevels([]);
                          setSelectedDepartments([]);
                          setSelectedCompanySizes([]);
                          setSelectedRevenueRanges([]);
                          setSelectedVerticals([]);
                          setSelectedLocations([]);
                          setSelectedHQDivisions([]);
                          setSelectedJobFunctions([]);
                          setSelectedTopicClusters([]);
                          setExcludedVerticals([]);
                          setExcludedDepartments([]);
                          setExcludedJobFunctions([]);
                        }}
                      >
                        Clear All Filters
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardContent>
                  {/* Filter Warnings */}
                  {getFilterWarnings().length > 0 &&
                    getFilterWarnings().map((warning, index) => (
                      <Alert key={index} variant="warning" className="mb-4">
                        <AlertCircle className="w-4 h-4" />
                        <AlertDescription>{warning}</AlertDescription>
                      </Alert>
                    ))}
                  <Accordion type="single" collapsible className="w-full">
                    {/* Contact Filters */}
                    <AccordionItem value="contact-filters">
                      <AccordionTrigger className="hover:no-underline">
                        <div className="flex items-center gap-2">
                          <Users className="w-4 h-4" />
                          <span>Contact Filters</span>
                          {(selectedJobLevels.length > 0 ||
                            selectedDepartments.length > 0 ||
                            selectedJobFunctions.length > 0 ||
                            selectedLocations.length > 0) && (
                            <Badge variant="secondary" className="ml-2">
                              {selectedJobLevels.length +
                                selectedDepartments.length +
                                selectedJobFunctions.length +
                                selectedLocations.length}{" "}
                              selected
                            </Badge>
                          )}
                        </div>
                      </AccordionTrigger>
                      <AccordionContent>
                        <div className="space-y-4 pt-4">
                          {/* Search Bar */}
                          <div className="relative mb-4">
                            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" />
                            <input
                              type="text"
                              placeholder="Search filters..."
                              className="w-full pl-9 pr-4 py-2 border rounded-md text-sm"
                            />
                          </div>

                          {/* Job Level */}
                          <div>
                            <div className="flex justify-between items-center mb-2">
                              <label className="text-sm font-medium text-gray-700">
                                Job Level
                              </label>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <HelpCircle className="w-4 h-4 text-gray-400" />
                                </TooltipTrigger>
                                <TooltipContent>
                                  Select multiple job levels to target
                                </TooltipContent>
                              </Tooltip>
                            </div>
                            <select
                              className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                              onChange={handleJobLevelSelect}
                              value=""
                            >
                              <option value="">Select job level</option>
                              {jobLevelOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>

                            {/* Selected Job Levels Chips */}
                            <div className="flex flex-wrap gap-2 mt-2">
                              {selectedJobLevels.map((level) => {
                                const option = jobLevelOptions.find(
                                  (opt) => opt.value === level,
                                );
                                return (
                                  <div
                                    key={level}
                                    className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                  >
                                    <span>{option?.label}</span>
                                    <button
                                      onClick={() => removeJobLevel(level)}
                                      className="ml-2 focus:outline-none"
                                    >
                                      <X className="w-3 h-3" />
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          {/* Department */}
                          <div>
                            <div className="flex justify-between items-center mb-2">
                              <label className="text-sm font-medium text-gray-700">
                                Department
                              </label>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <HelpCircle className="w-4 h-4 text-gray-400" />
                                </TooltipTrigger>
                                <TooltipContent>
                                  Select departments to target
                                </TooltipContent>
                              </Tooltip>
                            </div>
                            <select
                              className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                              onChange={handleDepartmentSelect}
                              value=""
                            >
                              <option value="">Select department</option>
                              {departmentOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>

                            {/* Selected Departments Chips */}
                            <div className="flex flex-wrap gap-2 mt-2">
                              {selectedDepartments.map((department) => {
                                const option = departmentOptions.find(
                                  (opt) => opt.value === department,
                                );
                                return (
                                  <div
                                    key={department}
                                    className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                  >
                                    <span>{option?.label}</span>
                                    <button
                                      onClick={() =>
                                        removeDepartment(department)
                                      }
                                      className="ml-2 focus:outline-none"
                                    >
                                      <X className="w-3 h-3" />
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          {/* Job Function */}
                          <div>
                            <div className="flex justify-between items-center mb-2">
                              <label className="text-sm font-medium text-gray-700">
                                Job Function
                              </label>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <HelpCircle className="w-4 h-4 text-gray-400" />
                                </TooltipTrigger>
                                <TooltipContent>
                                  Select specific job functions within
                                  departments
                                </TooltipContent>
                              </Tooltip>
                            </div>
                            <select
                              className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                              onChange={handleJobFunctionSelect}
                              value=""
                            >
                              <option value="">Select job function</option>
                              {jobFunctionsByDepartment.map((dept) => (
                                <optgroup
                                  key={dept.department}
                                  label={dept.department}
                                >
                                  {dept.functions.map((func) => (
                                    <option key={func.value} value={func.value}>
                                      {func.label}
                                    </option>
                                  ))}
                                </optgroup>
                              ))}
                            </select>

                            {/* Selected Job Functions Chips */}
                            <div className="flex flex-wrap gap-2 mt-2">
                              {selectedJobFunctions.map((funcValue) => {
                                const func = jobFunctionsByDepartment
                                  .flatMap((dept) => dept.functions)
                                  .find((f) => f.value === funcValue);
                                return (
                                  <div
                                    key={funcValue}
                                    className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                  >
                                    <span>{func?.label}</span>
                                    <button
                                      onClick={() =>
                                        removeJobFunction(funcValue)
                                      }
                                      className="ml-2 focus:outline-none"
                                    >
                                      <X className="w-3 h-3" />
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          {/* Location */}
                          <div>
                            <div className="flex justify-between items-center mb-2">
                              <label className="text-sm font-medium text-gray-700">
                                Location
                              </label>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <HelpCircle className="w-4 h-4 text-gray-400" />
                                </TooltipTrigger>
                                <TooltipContent>
                                  Select location types
                                </TooltipContent>
                              </Tooltip>
                            </div>
                            <select
                              className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                              onChange={handleLocationSelect}
                              value=""
                            >
                              <option value="">Select location</option>
                              {locationOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>

                            {/* Selected Locations Chips */}
                            <div className="flex flex-wrap gap-2 mt-2">
                              {selectedLocations.map((location) => {
                                const option = locationOptions.find(
                                  (opt) => opt.value === location,
                                );
                                return (
                                  <div
                                    key={location}
                                    className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                  >
                                    <span>{option?.label}</span>
                                    <button
                                      onClick={() => removeLocation(location)}
                                      className="ml-2 focus:outline-none"
                                    >
                                      <X className="w-3 h-3" />
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </AccordionContent>
                    </AccordionItem>

                    {/* Company Filters */}
                    <AccordionItem value="company-filters">
                      <AccordionTrigger className="hover:no-underline">
                        <div className="flex items-center gap-2">
                          <Building className="w-4 h-4" />
                          <span>Company Filters</span>
                          {(selectedCompanySizes.length > 0 ||
                            selectedVerticals.length > 0 ||
                            selectedRevenueRanges.length > 0 ||
                            selectedHQDivisions.length > 0) && (
                            <Badge variant="secondary" className="ml-2">
                              {selectedCompanySizes.length +
                                selectedVerticals.length +
                                selectedRevenueRanges.length +
                                selectedHQDivisions.length}{" "}
                              selected
                            </Badge>
                          )}
                        </div>
                      </AccordionTrigger>
                      <AccordionContent>
                        <div className="space-y-4 pt-4">
                          {/* Search Bar */}
                          <div className="relative mb-4">
                            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" />
                            <input
                              type="text"
                              placeholder="Search company filters..."
                              className="w-full pl-9 pr-4 py-2 border rounded-md text-sm"
                            />
                          </div>

                          {/* Company Size */}
                          <div>
                            <div className="flex justify-between items-center mb-2">
                              <label className="text-sm font-medium text-gray-700">
                                Company Size
                              </label>
                              <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => setSelectedCompanySizes([])}
                              >
                                Clear
                              </Button>
                            </div>
                            <select
                              className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                              onChange={handleCompanySizeSelect}
                              value=""
                            >
                              <option value="">Select company size</option>
                              {companySizeOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            <div className="flex flex-wrap gap-2 mt-2">
                              {selectedCompanySizes.map((size) => {
                                const option = companySizeOptions.find(
                                  (opt) => opt.value === size,
                                );
                                return (
                                  <div
                                    key={size}
                                    className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                  >
                                    <span>{option?.label}</span>
                                    <button
                                      onClick={() => removeCompanySize(size)}
                                      className="ml-2 focus:outline-none"
                                    >
                                      <X className="w-3 h-3" />
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          {/* Vertical */}
                          <div>
                            <div className="flex justify-between items-center mb-2">
                              <label className="text-sm font-medium text-gray-700">
                                Vertical
                              </label>
                              <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => setSelectedVerticals([])}
                              >
                                Clear
                              </Button>
                            </div>
                            <select
                              className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                              onChange={handleVerticalSelect}
                              value=""
                            >
                              <option value="">Select vertical</option>
                              {verticalOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            <div className="flex flex-wrap gap-2 mt-2">
                              {selectedVerticals.map((vertical) => {
                                const option = verticalOptions.find(
                                  (opt) => opt.value === vertical,
                                );
                                return (
                                  <div
                                    key={vertical}
                                    className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                  >
                                    <span>{option?.label}</span>
                                    <button
                                      onClick={() => removeVertical(vertical)}
                                      className="ml-2 focus:outline-none"
                                    >
                                      <X className="w-3 h-3" />
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          {/* Annual Revenue */}
                          <div>
                            <div className="flex justify-between items-center mb-2">
                              <label className="text-sm font-medium text-gray-700">
                                Annual Revenue
                              </label>
                              <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => setSelectedRevenueRanges([])}
                              >
                                Clear
                              </Button>
                            </div>
                            <select
                              className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                              onChange={handleRevenueRangeSelect}
                              value=""
                            >
                              <option value="">Select revenue range</option>
                              {revenueRangeOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            <div className="flex flex-wrap gap-2 mt-2">
                              {selectedRevenueRanges.map((range) => {
                                const option = revenueRangeOptions.find(
                                  (opt) => opt.value === range,
                                );
                                return (
                                  <div
                                    key={range}
                                    className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                  >
                                    <span>{option?.label}</span>
                                    <button
                                      onClick={() => removeRevenueRange(range)}
                                      className="ml-2 focus:outline-none"
                                    >
                                      <X className="w-3 h-3" />
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          {/* Headquarter Division */}
                          <div>
                            <div className="flex justify-between items-center mb-2">
                              <label className="text-sm font-medium text-gray-700">
                                Headquarter Division
                              </label>
                              <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => setSelectedHQDivisions([])}
                              >
                                Clear
                              </Button>
                            </div>
                            <select
                              className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                              onChange={handleHQDivisionSelect}
                              value=""
                            >
                              <option value="">
                                Select headquarter division
                              </option>
                              {hqDivisionOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            <div className="flex flex-wrap gap-2 mt-2">
                              {selectedHQDivisions.map((division) => {
                                const option = hqDivisionOptions.find(
                                  (opt) => opt.value === division,
                                );
                                return (
                                  <div
                                    key={division}
                                    className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                  >
                                    <span>{option?.label}</span>
                                    <button
                                      onClick={() => removeHQDivision(division)}
                                      className="ml-2 focus:outline-none"
                                    >
                                      <X className="w-3 h-3" />
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          {/* Surging Intent */}
                          <div>
                            <div className="flex justify-between items-center mb-2">
                              <label className="text-sm font-medium text-gray-700">
                                Surging Intent
                              </label>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <HelpCircle className="w-4 h-4 text-gray-400" />
                                </TooltipTrigger>
                                <TooltipContent>
                                  Select topics showing increased interest
                                </TooltipContent>
                              </Tooltip>
                            </div>
                            <div className="flex items-center gap-2 mt-1">
                              <select
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                                onChange={handleTopicClusterSelect}
                                value=""
                              >
                                <option value="">Select topic cluster</option>
                                {topicClusterOptions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>

                            {/* Selected Topic Clusters Chips */}
                            <div className="flex flex-wrap gap-2 mt-2">
                              {selectedTopicClusters.map((cluster) => {
                                const option = topicClusterOptions.find(
                                  (opt) => opt.value === cluster,
                                );
                                return (
                                  <div
                                    key={cluster}
                                    className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                  >
                                    <TrendingUp className="w-3 h-3 mr-1" />
                                    <span>{option?.label}</span>
                                    <button
                                      onClick={() =>
                                        removeTopicCluster(cluster)
                                      }
                                      className="ml-2 focus:outline-none"
                                    >
                                      <X className="w-3 h-3" />
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </AccordionContent>
                    </AccordionItem>

                    {/* Exclusions */}
                    <AccordionItem value="exclusions">
                      <AccordionTrigger className="hover:no-underline">
                        <div className="flex items-center gap-2">
                          <Filter className="w-4 h-4" />
                          <span>Exclusions</span>
                          {(excludedVerticals.length > 0 ||
                            excludedDepartments.length > 0 ||
                            excludedJobFunctions.length > 0) && (
                            <Badge variant="secondary" className="ml-2">
                              {excludedVerticals.length +
                                excludedDepartments.length +
                                excludedJobFunctions.length}{" "}
                              excluded
                            </Badge>
                          )}
                        </div>
                      </AccordionTrigger>
                      <AccordionContent>
                        <div className="space-y-4 pt-4">
                          {/* Search Bar */}
                          <div className="relative mb-4">
                            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" />
                            <input
                              type="text"
                              placeholder="Search exclusions..."
                              className="w-full pl-9 pr-4 py-2 border rounded-md text-sm"
                            />
                          </div>

                          {/* Excluded Verticals */}
                          <div>
                            <div className="flex justify-between items-center mb-2">
                              <label className="text-sm font-medium text-gray-700">
                                Excluded Verticals
                              </label>
                              <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => setExcludedVerticals([])}
                              >
                                Clear
                              </Button>
                            </div>
                            <select
                              className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                              onChange={handleExcludedVerticalSelect}
                              value=""
                            >
                              <option value="">
                                Select verticals to exclude
                              </option>
                              {verticalOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>

                            {/* Excluded Verticals Chips */}
                            <div className="flex flex-wrap gap-2 mt-2">
                              {excludedVerticals.map((vertical) => {
                                const option = verticalOptions.find(
                                  (opt) => opt.value === vertical,
                                );
                                return (
                                  <div
                                    key={vertical}
                                    className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                  >
                                    <span>{option?.label}</span>
                                    <button
                                      onClick={() =>
                                        removeExcludedVertical(vertical)
                                      }
                                      className="ml-2 focus:outline-none"
                                    >
                                      <X className="w-3 h-3" />
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          {/* Excluded Departments */}
                          <div>
                            <div className="flex justify-between items-center mb-2">
                              <label className="text-sm font-medium text-gray-700">
                                Excluded Departments
                              </label>
                              <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => setExcludedDepartments([])}
                              >
                                Clear
                              </Button>
                            </div>
                            <select
                              className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                              onChange={handleExcludedDepartmentSelect}
                              value=""
                            >
                              <option value="">
                                Select departments to exclude
                              </option>
                              {departmentOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>

                            {/* Excluded Departments Chips */}
                            <div className="flex flex-wrap gap-2 mt-2">
                              {excludedDepartments.map((department) => {
                                const option = departmentOptions.find(
                                  (opt) => opt.value === department,
                                );
                                return (
                                  <div
                                    key={department}
                                    className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                  >
                                    <span>{option?.label}</span>
                                    <button
                                      onClick={() =>
                                        removeExcludedDepartment(department)
                                      }
                                      className="ml-2 focus:outline-none"
                                    >
                                      <X className="w-3 h-3" />
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          {/* Excluded Job Functions */}
                          <div>
                            <div className="flex justify-between items-center mb-2">
                              <label className="text-sm font-medium text-gray-700">
                                Excluded Job Functions
                              </label>
                              <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => setExcludedJobFunctions([])}
                              >
                                Clear
                              </Button>
                            </div>
                            <select
                              className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                              onChange={handleExcludedJobFunctionSelect}
                              value=""
                            >
                              <option value="">
                                Select job functions to exclude
                              </option>
                              {jobFunctionsByDepartment.map((dept) => (
                                <optgroup
                                  key={dept.department}
                                  label={dept.department}
                                >
                                  {dept.functions.map((func) => (
                                    <option key={func.value} value={func.value}>
                                      {func.label}
                                    </option>
                                  ))}
                                </optgroup>
                              ))}
                            </select>

                            {/* Excluded Job Functions Chips */}
                            <div className="flex flex-wrap gap-2 mt-2">
                              {excludedJobFunctions.map((funcValue) => {
                                const func = jobFunctionsByDepartment
                                  .flatMap((dept) => dept.functions)
                                  .find((f) => f.value === funcValue);
                                return (
                                  <div
                                    key={funcValue}
                                    className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                  >
                                    <span>{func?.label}</span>
                                    <button
                                      onClick={() =>
                                        removeExcludedJobFunction(funcValue)
                                      }
                                      className="ml-2 focus:outline-none"
                                    >
                                      <X className="w-3 h-3" />
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </CardContent>
              </Card>
            </div>
          </div>

          {/* Right Column - Summary */}
          <div className="col-span-7 space-y-6">
            <AudienceDataProvider auth={auth}>
              <AudienceContent />
            </AudienceDataProvider>
          </div>
        </div>
      </div>
    </TooltipProvider>
  );
};

export default AudienceBuilder;
