import React, { useState, useMemo } from 'react';
import { scaleQuantize } from 'd3-scale';
import { geoPath, geoAlbersUsa } from 'd3-geo';
import { feature } from 'topojson-client';
import usAtlas from 'us-atlas/states-10m.json';
import { StateList } from './StateList';
import { useAudienceData } from './AudienceDataProvider';
import { Card, CardHeader, CardTitle, CardContent } from '../../user/components/ui/card';
import { Skeleton } from "../../shared/components/UIElements/skeleton";

const GeoDistributionSkeleton = () => {
  return (
    <Card className="w-full">
      <CardHeader>
        <Skeleton className="h-7 w-48 animate-pulse bg-gray-200" />
      </CardHeader>
      <CardContent>
        <div className="relative h-[400px]">
          {/* Main map area skeleton */}
          <div className="absolute inset-0">
            <Skeleton className="w-full h-full rounded-lg animate-pulse bg-gray-200" />
          </div>
          
          {/* Legend skeleton */}
          <div className="absolute bottom-4 right-4 bg-white p-3 rounded shadow-sm">
            <Skeleton className="h-4 w-20 mb-2 animate-pulse bg-gray-200" />
            <div className="space-y-2">
              {[...Array(5)].map((_, i) => (
                <div key={i} className="flex items-center gap-2">
                  <Skeleton className="h-4 w-4 rounded animate-pulse bg-gray-200" />
                  <Skeleton className="h-3 w-16 animate-pulse bg-gray-200" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const GeoDistribution = () => {
  const { audienceData, isLoading } = useAudienceData();
  const [tooltip, setTooltip] = useState({ show: false, content: '', x: 0, y: 0 });

  // Add null check for geoDistribution
  const geoDistribution = audienceData?.geoDistribution || [];

  const projection = geoAlbersUsa();
  const path = geoPath(projection);
  const states = feature(usAtlas, usAtlas.objects.states);

  // Create a mapping object from state abbreviation to full name
  const stateAbbrevToName = Object.fromEntries(
    StateList.map(state => [state.value, state.label])
  );

  // Process the geo distribution data
  const processedGeoData = useMemo(() => {
    return geoDistribution
      .filter(d => d && d.state !== '__HIVE_DEFAULT_PARTITION__')
      .map(d => ({
        state: stateAbbrevToName[d.state] || d.state,
        value: d.value
      }));
  }, [geoDistribution, stateAbbrevToName]);

  // Calculate dynamic thresholds based on the data
  const colorScale = useMemo(() => {
    const values = processedGeoData.map(d => d.value);
    const maxValue = Math.max(...values, 1); // Use 1 as minimum to avoid division by zero
    
    return scaleQuantize()
      .domain([0, maxValue])
      .range([
        'rgb(237, 233, 254)', // Lightest purple
        'rgb(221, 214, 254)',
        'rgb(196, 181, 253)',
        'rgb(167, 139, 250)',
        'rgb(139, 92, 246)'   // Darkest purple
      ]);
  }, [processedGeoData]);

  // Get dynamic legend values
  const legendValues = useMemo(() => {
    if (processedGeoData.length === 0) return [0, 0, 0, 0, 0];
    const maxValue = Math.max(...processedGeoData.map(d => d.value));
    
    // Helper function to round to a nice number
    const roundToNice = (num) => {
      const magnitude = Math.pow(10, Math.floor(Math.log10(num)));
      return Math.round(num / magnitude) * magnitude;
    };

    const niceMax = roundToNice(maxValue);
    return [
      0,
      roundToNice(niceMax * 0.25),
      roundToNice(niceMax * 0.5),
      roundToNice(niceMax * 0.75),
      niceMax
    ];
  }, [processedGeoData]);

  const handleMouseMove = (e, stateName) => {
    const stateData = processedGeoData.find(d => d.state === stateName);
    if (stateData) {
      const tooltipX = e.pageX;
      const tooltipY = e.pageY;
      setTooltip({
        show: true,
        content: `${stateName}: ${stateData.value.toLocaleString()} companies`,
        x: tooltipX,
        y: tooltipY
      });
    }
  };

  const handleMouseLeave = () => {
    setTooltip({ ...tooltip, show: false });
  };

  if (isLoading) {
    return <GeoDistributionSkeleton />;
  }

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Geographic Distribution</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="relative h-[400px]">
          {processedGeoData.length > 0 ? (
            <>
              <svg viewBox="0 0 975 610" className="w-full h-full">
                {states.features.map((feature) => {
                  const stateName = feature.properties.name;
                  const stateData = processedGeoData.find(d => d.state === stateName);
                  return (
                    <path
                      key={stateName}
                      d={path(feature)}
                      fill={stateData ? colorScale(stateData.value) : '#eee'}
                      stroke="#fff"
                      strokeWidth={0.5}
                      className="hover:opacity-80 cursor-pointer transition-opacity"
                      onMouseMove={(e) => handleMouseMove(e, stateName)}
                      onMouseLeave={handleMouseLeave}
                    />
                  );
                })}
              </svg>
              
              {/* Vertical Legend */}
              <div className="absolute bottom-4 right-4 bg-white p-3 rounded shadow-sm">
                <div className="text-xs font-medium mb-2">Companies</div>
                <div className="flex flex-col-reverse gap-1">
                  {colorScale.range().map((color, i) => (
                    <div key={i} className="flex items-center gap-2">
                      <div
                        className="w-4 h-4"
                        style={{ backgroundColor: color }}
                      />
                      <span className="text-xs">
                        {i === 0 ? '0' : 
                         i === colorScale.range().length - 1 ? `${legendValues[4].toLocaleString()}+` :
                         `${legendValues[i].toLocaleString()}`}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <div className="h-full flex items-center justify-center text-gray-500">
              No geographic data available
            </div>
          )}
          
          {/* Tooltip */}
          {tooltip.show && (
            <div
              className="fixed bg-white px-3 py-2 rounded shadow-lg text-sm pointer-events-none z-50"
              style={{
                left: `${tooltip.x + 10}px`,
                top: `${tooltip.y + 10}px`,
                transform: 'translate(-50%, -100%)'
              }}
            >
              {tooltip.content}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default GeoDistribution;
