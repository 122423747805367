import React from "react";
import { useAudienceData } from "./AudienceDataProvider";
import { Building, Users, Mail, Phone, Linkedin, MapPin } from "lucide-react";

// Skeleton component for a single summary card
const SummaryCardSkeleton = () => (
  <div className="bg-white p-4 rounded-lg shadow-sm border">
    <div className="flex items-center gap-2 mb-2">
      <div className="w-4 h-4 bg-gray-200 rounded animate-pulse" />
      <div className="w-24 h-4 bg-gray-200 rounded animate-pulse" />
    </div>
    <div className="w-full h-8 bg-gray-200 rounded animate-pulse" />
  </div>
);

// Skeleton layout for the entire summary section
const AudienceSummarySkeletons = () => (
  <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
    {[...Array(8)].map((_, index) => (
      <SummaryCardSkeleton key={index} />
    ))}
  </div>
);

const AudienceSummary = () => {
  const { audienceData, isLoading } = useAudienceData();

  // Show skeleton loading state
  if (isLoading) {
    return <AudienceSummarySkeletons />;
  }

  const summary = {
    companies: audienceData?.summary?.companies || 0,
    contacts: audienceData?.summary?.contacts || 0,
    workEmails: audienceData?.summary?.workEmails || 0,
    personalEmails: audienceData?.summary?.personalEmails || 0,
    directPhones: audienceData?.summary?.directPhones || 0,
    mobilePhones: audienceData?.summary?.mobilePhones || 0,
    linkedinProfiles: audienceData?.summary?.linkedinProfiles || 0,
    addresses: audienceData?.summary?.addresses || 0,
  };

  const metrics = [
    {
      icon: Building,
      label: "Companies",
      value: summary.companies.toLocaleString(),
    },
    {
      icon: Users,
      label: "Contacts",
      value: summary.contacts.toLocaleString(),
    },
    {
      icon: Mail,
      label: "Work Emails",
      value: summary.workEmails.toLocaleString(),
    },
    {
      icon: Mail,
      label: "Personal Emails",
      value: summary.personalEmails.toLocaleString(),
    },
    {
      icon: Phone,
      label: "Direct Phones",
      value: summary.directPhones.toLocaleString(),
    },
    {
      icon: Phone,
      label: "Mobile Phones",
      value: summary.mobilePhones.toLocaleString(),
    },
    {
      icon: Linkedin,
      label: "LinkedIn Profiles",
      value: summary.linkedinProfiles.toLocaleString(),
    },
    {
      icon: MapPin,
      label: "Addresses",
      value: summary.addresses.toLocaleString(),
    },
  ];

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      {metrics.map(({ icon: Icon, label, value }) => (
        <div key={label} className="bg-white p-4 rounded-lg shadow-sm border">
          <div className="flex items-center gap-2 text-gray-600 mb-2">
            <Icon className="w-4 h-4" />
            <span className="text-sm font-medium">{label}</span>
          </div>
          <div className="text-2xl font-semibold">{value}</div>
        </div>
      ))}
    </div>
  );
};

export default AudienceSummary;
