import React, { useState } from "react";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../shared/components/UIElements/alert-dialog";
import { Button } from "../../teamhub/components/ui/button";
import { Card } from "../../user/components/ui/card";
import { X } from "lucide-react";

const TutorialWalkthrough = ({ isOpen, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const tutorialSteps = [
    {
      step: 1,
      title: "Name Your Audience",
      description:
        "Start by giving your audience a meaningful name that describes who you're targeting.",
      targetId: "audience-name-section",
    },
    {
      step: 2,
      title: "Define Your Filters",
      description:
        "Use the filter sections to narrow down your audience by job level, department, company size, and more.",
      targetId: "filters-section",
    },
    {
      step: 3,
      title: "Review Your Audience",
      description:
        "Click 'Fetch Audience' to see your results. Switch between Summary and Analytics tabs to get detailed insights about your audience's composition and distribution.",
      targetId: "audience-content",
    },
    {
      step: 4,
      title: "Save Your Work",
      description:
        "Once you're satisfied with your audience, click 'Save Audience' to store it for future use. You can always modify and update it later.",
      targetId: "save-audience-button",
    },
  ];

  const getStepPosition = (targetId) => {
    const element = document.getElementById(targetId);
    if (!element) return { left: 0, top: 0 };
    
    const rect = element.getBoundingClientRect();
    const tooltipWidth = 256; // w-64 = 16rem = 256px
    const tooltipHeight = 200; // approximate height of tooltip
    const margin = 16;
    
    // Get viewport dimensions
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    
    // Calculate initial position
    let left = rect.right + margin;
    let top = rect.top;
    
    // Check right boundary
    if (left + tooltipWidth > viewportWidth) {
      // Place on left side if there's not enough space on right
      left = rect.left - tooltipWidth - margin;
      
      // If still no space, place it above or below centered
      if (left < 0) {
        left = rect.left + (rect.width - tooltipWidth) / 2;
        top = rect.bottom + margin; // Place below
        
        // If no space below, place above
        if (top + tooltipHeight > viewportHeight) {
          top = rect.top - tooltipHeight - margin;
        }
      }
    }
    
    // Check bottom boundary
    if (top + tooltipHeight > viewportHeight) {
      top = viewportHeight - tooltipHeight - margin;
    }
    
    // Check top boundary
    if (top < 0) {
      top = margin;
    }
    
    return { left, top };
  };

  const handleNext = () => {
    if (currentStep < tutorialSteps.length) {
      setCurrentStep(currentStep + 1);
    } else {
      onClose();
      setCurrentStep(0);
    }
  };

  const handleSkip = () => {
    onClose();
    setCurrentStep(0);
  };

  return (
    <>
      {/* Initial Tutorial Dialog */}
      {isOpen && currentStep === 0 && (
        <AlertDialog open={true}>
          <AlertDialogContent className="max-w-md">
            <AlertDialogHeader>
              <AlertDialogTitle>Welcome to Audience Builder</AlertDialogTitle>
              <AlertDialogDescription>
                Let's walk through how to create your first audience. We'll
                guide you through each step of the process.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <div className="flex justify-end gap-2 mt-4">
              <Button variant="outline" onClick={handleSkip}>
                Skip Tutorial
              </Button>
              <Button onClick={handleNext}>Start Tour</Button>
            </div>
          </AlertDialogContent>
        </AlertDialog>
      )}

      {/* Step Popovers */}
      {isOpen && currentStep > 0 && currentStep <= tutorialSteps.length && (
        <div className="fixed inset-0 z-50 pointer-events-none bg-black/50">
          <div className="relative w-full h-full">
            {tutorialSteps.map((step, index) => (
              <React.Fragment key={step.step}>
                {currentStep === step.step && (
                  <div
                    className="absolute pointer-events-auto transition-all duration-200 ease-in-out"
                    style={{
                      left: getStepPosition(step.targetId).left,
                      top: getStepPosition(step.targetId).top,
                    }}
                  >
                    <Card className="p-4 w-64 shadow-lg relative bg-white">
                      <Button
                        size="icon"
                        variant="ghost"
                        className="absolute right-2 top-2"
                        onClick={handleSkip}
                      >
                        <X className="h-4 w-4" />
                      </Button>
                      <div className="flex items-start gap-3 mb-3">
                        <div className="flex-shrink-0 w-6 h-6 rounded-full bg-purple-600 text-white flex items-center justify-center text-sm font-medium">
                          {step.step}
                        </div>
                        <div>
                          <h4 className="font-medium mb-1">{step.title}</h4>
                          <p className="text-sm text-gray-600">
                            {step.description}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-end gap-2">
                        {step.step !== tutorialSteps.length && (
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={handleSkip}
                          >
                            Skip
                          </Button>
                        )}
                        <Button size="sm" onClick={handleNext}>
                          {step.step === tutorialSteps.length
                            ? "Finish"
                            : "Next"}
                        </Button>
                      </div>
                    </Card>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default TutorialWalkthrough;
