import React from 'react';
import { Calendar } from 'lucide-react';

const CalendarEventsHeader = ({ selectedDate }) => {
  const displayMonth = selectedDate 
    ? selectedDate.toLocaleString('default', { month: 'long', year: 'numeric' })
    : new Date().toLocaleString('default', { month: 'long', year: 'numeric' });
  
  return (
    <div className="bg-white pb-3">
      {/* Top decorative line */}
      <div className="h-0.5 bg-gradient-to-r from-blue-100 via-blue-50 to-transparent mb-3" />
      
      <div className="flex items-center space-x-3">
        <div className="p-2 bg-blue-50 rounded-lg">
          <Calendar className="h-5 w-5 text-blue-600" />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-900">Events This Month</h2>
          <p className="text-sm text-gray-500">{displayMonth}</p>
        </div>
      </div>
      
      {/* Bottom decorative line */}
      <div className="mt-3 h-0.5 bg-gradient-to-r from-blue-100 via-blue-50 to-transparent" />
    </div>
  );
};

export default CalendarEventsHeader;
