import React from "react";
import { useAudienceData } from "./AudienceDataProvider";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../user/components/ui/card";
import { Building, Mail, Phone, MapPin, Linkedin } from "lucide-react";
import { Skeleton } from "../../shared/components/UIElements/skeleton";

const TopCompaniesCardSkeleton = () => {
  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
        <Skeleton className="h-6 w-32 animate-pulse bg-gray-200" />
        <Skeleton className="h-4 w-48 animate-pulse bg-gray-200" />
      </CardHeader>
      <CardContent>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b">
                <th className="text-left py-3">
                  <Skeleton className="h-4 w-24 animate-pulse bg-gray-200" />
                </th>
                <th className="text-right py-3">
                  <Skeleton className="h-4 w-16 ml-auto animate-pulse bg-gray-200" />
                </th>
                <th className="text-right py-3">
                  <Skeleton className="h-4 w-16 ml-auto animate-pulse bg-gray-200" />
                </th>
                <th className="text-right py-3">
                  <Skeleton className="h-4 w-16 ml-auto animate-pulse bg-gray-200" />
                </th>
                <th className="text-right py-3">
                  <Skeleton className="h-4 w-16 ml-auto animate-pulse bg-gray-200" />
                </th>
                <th className="text-right py-3">
                  <Skeleton className="h-4 w-16 ml-auto animate-pulse bg-gray-200" />
                </th>
                <th className="text-right py-3">
                  <Skeleton className="h-4 w-16 ml-auto animate-pulse bg-gray-200" />
                </th>
                <th className="text-right py-3">
                  <Skeleton className="h-4 w-16 ml-auto animate-pulse bg-gray-200" />
                </th>
              </tr>
            </thead>
            <tbody>
              {[...Array(5)].map((_, index) => (
                <tr key={index} className="border-b">
                  <td className="py-3">
                    <div className="flex items-center gap-2">
                      <div className="w-4 h-4 bg-gray-200 rounded animate-pulse" />
                      <Skeleton className="h-4 w-32 animate-pulse bg-gray-200" />
                    </div>
                  </td>
                  <td className="text-right py-3">
                    <Skeleton className="h-4 w-12 ml-auto animate-pulse bg-gray-200" />
                  </td>
                  <td className="text-right py-3">
                    <Skeleton className="h-4 w-12 ml-auto animate-pulse bg-gray-200" />
                  </td>
                  <td className="text-right py-3">
                    <Skeleton className="h-4 w-12 ml-auto animate-pulse bg-gray-200" />
                  </td>
                  <td className="text-right py-3">
                    <Skeleton className="h-4 w-12 ml-auto animate-pulse bg-gray-200" />
                  </td>
                  <td className="text-right py-3">
                    <Skeleton className="h-4 w-12 ml-auto animate-pulse bg-gray-200" />
                  </td>
                  <td className="text-right py-3">
                    <Skeleton className="h-4 w-12 ml-auto animate-pulse bg-gray-200" />
                  </td>
                  <td className="text-right py-3">
                    <Skeleton className="h-4 w-12 ml-auto animate-pulse bg-gray-200" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CardContent>
    </Card>
  );
};

const TopCompaniesCard = () => {
  const { audienceData, isLoading } = useAudienceData();
  const companies = audienceData?.topCompanies || [];

  if (isLoading) {
    return <TopCompaniesCardSkeleton />;
  }

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
        <CardTitle className="text-xl font-bold">Top Companies</CardTitle>
        <div className="text-sm text-gray-500">
          Showing top 10 by contact count
        </div>
      </CardHeader>
      <CardContent>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b">
                <th className="text-left py-3 font-medium text-gray-600">
                  Company Name
                </th>
                <th className="text-right py-3 font-medium text-gray-600">
                  Contacts
                </th>
                <th className="text-right py-3 font-medium text-gray-600">
                  <div className="flex items-center justify-end gap-2">
                    <Mail className="w-4 h-4" />
                    <span>Work</span>
                  </div>
                </th>
                <th className="text-right py-3 font-medium text-gray-600">
                  <div className="flex items-center justify-end gap-2">
                    <Mail className="w-4 h-4" />
                    <span>Personal</span>
                  </div>
                </th>
                <th className="text-right py-3 font-medium text-gray-600">
                  <div className="flex items-center justify-end gap-2">
                    <Phone className="w-4 h-4" />
                    <span>Direct</span>
                  </div>
                </th>
                <th className="text-right py-3 font-medium text-gray-600">
                  <div className="flex items-center justify-end gap-2">
                    <Phone className="w-4 h-4" />
                    <span>Mobile</span>
                  </div>
                </th>
                <th className="text-right py-3 font-medium text-gray-600">
                  <div className="flex items-center justify-end gap-2">
                    <Linkedin className="w-4 h-4" />
                    <span>LinkedIn</span>
                  </div>
                </th>
                <th className="text-right py-3 font-medium text-gray-600">
                  <div className="flex items-center justify-end gap-2">
                    <MapPin className="w-4 h-4" />
                    <span>Addresses</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {companies.length === 0 ? (
                <tr>
                  <td colSpan={8} className="text-center py-4 text-gray-500">
                    No companies found. Try adjusting your filters.
                  </td>
                </tr>
              ) : (
                companies.map((company, index) => (
                  <tr key={index} className="border-b">
                    <td className="py-3">
                      <div className="flex items-center gap-2">
                        <Building className="w-4 h-4 text-gray-400" />
                        <span className="font-medium">
                          {company.businessName}
                        </span>
                      </div>
                    </td>
                    <td className="text-right py-3">
                      {company.contacts?.toLocaleString()}
                    </td>
                    <td className="text-right py-3">
                      {company.workEmails?.toLocaleString()}
                    </td>
                    <td className="text-right py-3">
                      {company.personalEmails?.toLocaleString()}
                    </td>
                    <td className="text-right py-3">
                      {company.directPhones?.toLocaleString()}
                    </td>
                    <td className="text-right py-3">
                      {company.mobilePhones?.toLocaleString()}
                    </td>
                    <td className="text-right py-3">
                      {company.linkedinProfiles?.toLocaleString()}
                    </td>
                    <td className="text-right py-3">
                      {company.addresses?.toLocaleString()}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </CardContent>
    </Card>
  );
};

export default TopCompaniesCard;
