import React, { useState } from 'react';
import { Rocket, PinOff, GripVertical } from 'lucide-react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable';

const PinnedHeader = () => (
  <div className="flex items-center justify-between gap-4 bg-gradient-to-r from-blue-600 to-blue-400 p-4 rounded-t-xl">
    <div className="flex items-center gap-4">
      <Rocket className="text-white" size={24} />
      <h2 className="text-xl font-semibold text-white">
        Launchpad
      </h2>
    </div>
    <span className="text-sm text-blue-50">Drag to reorder • Click to launch</span>
  </div>
);

const SortableItem = ({ feature, onUnpin }) => {
  const [isHovered, setIsHovered] = useState(false);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: feature.title });

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    transition,
    zIndex: isDragging ? 1 : 0,
  };

  const handleUnpin = (e) => {
    e.stopPropagation();
    onUnpin(feature);
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`
        group relative flex h-12 items-center justify-between
        rounded-lg bg-white px-4 shadow-sm transition-all
        hover:bg-blue-50 hover:shadow-md
        ${isDragging ? 'opacity-50 bg-blue-50 shadow-lg scale-105' : ''}
      `}
    >
      <div
        className="flex flex-1 items-center gap-3 overflow-hidden cursor-pointer"
        onClick={() => {
          if (!isDragging && typeof feature.onClick === 'function') {
            feature.onClick();
          }
        }}
      >
        {feature.icon &&
          React.cloneElement(feature.icon, {
            size: 20,
            className: 'text-blue-500',
          })}
        <span className="truncate font-medium text-gray-700">
          {feature.title}
        </span>
      </div>

      {isHovered && (
        <button
          onClick={handleUnpin}
          className="z-10 mr-2 rounded-full p-1.5 text-gray-400 hover:bg-gray-100 hover:text-gray-600"
        >
          <PinOff size={16} />
        </button>
      )}

      <div
        className="cursor-move p-1.5"
        onClick={(e) => e.stopPropagation()}
        {...attributes}
        {...listeners}
      >
        <GripVertical
          size={18}
          className="text-gray-400 group-hover:text-gray-600"
        />
      </div>
    </div>
  );
};

const DraggablePinnedApps = ({
  pinnedCards = [],
  onReorder = () => {},
  onUnpin = () => {},
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const oldIndex = pinnedCards.findIndex(
      (card) => card.title === active.id
    );
    const newIndex = pinnedCards.findIndex(
      (card) => card.title === over.id
    );

    onReorder(arrayMove(pinnedCards, oldIndex, newIndex));
  };

  return (
    <div className="mb-8 overflow-hidden rounded-xl border border-blue-100 bg-gradient-to-b from-white to-blue-50/30 shadow-lg">
      <PinnedHeader />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <div className="p-6">
          <SortableContext items={pinnedCards.map((card) => card.title)}>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {pinnedCards.map((feature) => (
                <SortableItem
                  key={feature.title}
                  feature={feature}
                  onUnpin={onUnpin}
                />
              ))}
            </div>
          </SortableContext>
        </div>
      </DndContext>
    </div>
  );
};

export default DraggablePinnedApps;
