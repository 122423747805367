import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
  Legend
} from 'recharts';
import { Card, CardHeader, CardTitle, CardContent } from '../../user/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../teamhub/components/ui/tabs';
import { useAudienceData } from './AudienceDataProvider';
import { Skeleton } from "../../shared/components/UIElements/skeleton";

const AnalyticsChartsSkeleton = () => {
  return (
    <Card className="w-full">
      <CardHeader>
        <Skeleton className="h-7 w-48 animate-pulse bg-gray-200" />
      </CardHeader>
      <CardContent>
        {/* Tab Skeleton */}
        <div className="w-full mb-4">
          <div className="flex gap-2">
            <Skeleton className="h-10 flex-1 animate-pulse bg-gray-200" />
            <Skeleton className="h-10 flex-1 animate-pulse bg-gray-200" />
          </div>
        </div>

        {/* Chart Area Skeleton */}
        <div className="space-y-4">
          <div className="flex justify-between items-end">
            <div className="space-y-1">
              <Skeleton className="h-4 w-24 animate-pulse bg-gray-200" />
              <Skeleton className="h-4 w-16 animate-pulse bg-gray-200" />
              <Skeleton className="h-4 w-20 animate-pulse bg-gray-200" />
            </div>
            <div className="flex-1 mx-4">
              <Skeleton className="h-[300px] animate-pulse bg-gray-200" />
            </div>
            <div className="space-y-1">
              <Skeleton className="h-4 w-16 animate-pulse bg-gray-200" />
              <Skeleton className="h-4 w-20 animate-pulse bg-gray-200" />
            </div>
          </div>
          
          {/* X-axis labels */}
          <div className="flex justify-between px-12">
            <Skeleton className="h-4 w-16 animate-pulse bg-gray-200" />
            <Skeleton className="h-4 w-16 animate-pulse bg-gray-200" />
            <Skeleton className="h-4 w-16 animate-pulse bg-gray-200" />
            <Skeleton className="h-4 w-16 animate-pulse bg-gray-200" />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const AnalyticsCharts = () => {
  const { audienceData, isLoading } = useAudienceData();
  
  // Add null checks and provide default values
  const distribution = audienceData?.distribution || {
    companySizes: [],
    industries: []
  };

  const customTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-4 shadow-lg rounded-lg border">
          <p className="font-medium">{label}</p>
          <p className="text-purple-600">
            {payload[0].value.toLocaleString()} companies
          </p>
        </div>
      );
    }
    return null;
  };

  if (isLoading) {
    return <AnalyticsChartsSkeleton />;
  }

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Distribution Analysis</CardTitle>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue="size" className="w-full">
          <TabsList className="w-full mb-4">
            <TabsTrigger value="size" className="flex-1">Company Size</TabsTrigger>
            <TabsTrigger value="industry" className="flex-1">Industry</TabsTrigger>
          </TabsList>
          
          <TabsContent value="size">
            <div className="h-80">
              {distribution?.companySizes?.length > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={distribution.companySizes} margin={{ left: 20 }}>
                    <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
                    <XAxis 
                      dataKey="name" 
                      tick={{ fontSize: 12 }}
                      angle={-45}
                      textAnchor="end"
                      height={80}
                    />
                    <YAxis width={80} />
                    <Tooltip content={customTooltip} />
                    <Legend />
                    <Bar 
                      dataKey="value" 
                      fill="#8b5cf6" 
                      radius={[4, 4, 0, 0]}
                      name="Companies"
                    />
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <div className="h-full flex items-center justify-center text-gray-500">
                  No company size data available
                </div>
              )}
            </div>
          </TabsContent>
          
          <TabsContent value="industry">
            <div className="h-96">
              {distribution?.industries?.length > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    layout="vertical"
                    data={distribution.industries}
                    margin={{ left: 180, right: 24, top: 8, bottom: 8 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
                    <XAxis type="number" />
                    <YAxis
                      dataKey="name"
                      type="category"
                      width={170}
                      tick={{ fontSize: 12 }}
                      interval={0}
                    />
                    <Tooltip content={customTooltip} />
                    <Legend />
                    <Bar 
                      dataKey="value" 
                      fill="#10b981" 
                      radius={[0, 4, 4, 0]}
                      name="Companies" 
                    />
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <div className="h-full flex items-center justify-center text-gray-500">
                  No industry data available
                </div>
              )}
            </div>
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default AnalyticsCharts;
