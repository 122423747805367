import React, { useState } from "react";
import { ChevronLeft, ChevronRight, Plus } from "lucide-react";
import { Button } from "../../user/components/ui/button";
import CalendarEventsHeader from "./CalendarEventsHeader";

const CalendarView = ({ events, onEventAdded, auth, hideAddButton }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  // Calendar navigation
  const goToPreviousMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
    );
  };

  const goToNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1),
    );
  };

  // Get days in month
  const getDaysInMonth = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const daysInMonth = lastDay.getDate();
    const startingDay = firstDay.getDay();

    const days = [];
    // Add empty cells for days before the first of the month
    for (let i = 0; i < startingDay; i++) {
      days.push(null);
    }
    // Add actual days
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(i);
    }
    return days;
  };

  // Get events for a specific day
  const getEventsForDay = (day) => {
    if (!day) return [];

    const targetDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day,
    );
    targetDate.setHours(0, 0, 0, 0);
    const targetDateStr = targetDate.toISOString().split("T")[0];

    return events.filter((event) => {
      const startDate = new Date(event.startDate);
      startDate.setHours(0, 0, 0, 0);
      const endDate = event.endDate ? new Date(event.endDate) : startDate;
      endDate.setHours(23, 59, 59, 999);

      return targetDate >= startDate && targetDate <= endDate;
    });
  };

  const getEventColor = (type) => {
    switch (type?.toLowerCase()) {
      case "meeting":
        return "bg-blue-100 text-blue-800";
      case "conference":
        return "bg-green-100 text-green-800";
      case "webinar":
        return "bg-orange-100 text-orange-800";
      case "deadline":
        return "bg-red-100 text-red-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  // Check if a date is today
  const isToday = (day) => {
    if (!day) return false;
    const today = new Date();
    return (
      today.getDate() === day &&
      today.getMonth() === currentDate.getMonth() &&
      today.getFullYear() === currentDate.getFullYear()
    );
  };

  return (
    <div className="space-y-6">
      {/* Calendar Header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <button
            onClick={goToPreviousMonth}
            className="p-1 hover:bg-gray-100 rounded-full"
          >
            <ChevronLeft className="h-5 w-5" />
          </button>
          <h2 className="text-lg font-semibold">
            {currentDate.toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </h2>
          <button
            onClick={goToNextMonth}
            className="p-1 hover:bg-gray-100 rounded-full"
          >
            <ChevronRight className="h-5 w-5" />
          </button>
        </div>
        {!hideAddButton && (
          <Button
            onClick={() => onEventAdded()}
            className="flex items-center space-x-2"
          >
            <Plus className="h-4 w-4" />
            <span>Add Event</span>
          </Button>
        )}
      </div>

      {/* Calendar Grid */}
      <div className="grid grid-cols-7 gap-px bg-gray-200 rounded-lg overflow-hidden">
        {/* Day Headers */}
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div
            key={day}
            className="bg-white p-2 text-center text-sm font-medium text-gray-600"
          >
            {day}
          </div>
        ))}

        {/* Calendar Days */}
        {getDaysInMonth().map((day, index) => {
          const dayEvents = getEventsForDay(day);

          return (
            <div
              key={index}
              className={`bg-white min-h-[120px] p-2 ${
                isToday(day) ? "bg-blue-50" : ""
              }`}
            >
              {day && (
                <>
                  <span
                    className={`text-sm ${
                      isToday(day) ? "font-bold text-blue-600" : "text-gray-600"
                    }`}
                  >
                    {day}
                  </span>
                  <div className="mt-1 space-y-1">
                    {dayEvents.slice(0, 3).map((event, eventIndex) => (
                      <div
                        key={event.event_id}
                        className={`text-xs px-2 py-1 rounded truncate ${getEventColor(event.type)}`}
                        title={`${event.title}${event.time ? ` at ${event.time}` : ""}`}
                      >
                        {event.time && `${event.time} `}
                        {event.title}
                      </div>
                    ))}
                    {dayEvents.length > 3 && (
                      <div className="text-xs text-gray-500">
                        +{dayEvents.length - 3} more
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>

      {/* Upcoming Events List */}
      <div className="mt-6">
        <CalendarEventsHeader selectedDate={currentDate} />
        <div className="space-y-2">
          {events
            .filter((event) => {
              const eventDate = new Date(event.startDate);
              return (
                eventDate.getMonth() === currentDate.getMonth() &&
                eventDate.getFullYear() === currentDate.getFullYear()
              );
            })
            .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
            .slice(0, 5)
            .map((event) => (
              <div
                key={event.event_id}
                className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg"
              >
                <div
                  className={`w-2 h-2 rounded-full ${getEventColor(event.type)}`}
                />
                <div>
                  <div className="font-medium text-sm">{event.title}</div>
                  <div className="text-xs text-gray-500">
                    {new Date(event.startDate).toLocaleDateString()}
                    {event.time && ` at ${event.time}`}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CalendarView;
