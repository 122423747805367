import React, { useState } from 'react';
import { Box, Toolbar } from '@mui/material';
import Header from './Header';
import MainFooter from './MainFooter';

const MainNavigation = () => {
  return (
    <React.Fragment>
      <div style={{ position: "relative" }}>
        <div style={{ height: "68px" }}></div> {/* Placeholder div */}
        <Header />
      </div>
      <MainFooter />
    </React.Fragment>
  );
};

export default MainNavigation;
