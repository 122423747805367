import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import UsersList from './UsersList';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../teamhub/components/ui/tabs';
import { Building2, Webhook, Share2 } from 'lucide-react';

const CategorizedAPIList = ({ items }) => {
  // Category definitions
  const categories = {
    firstParty: {
      title: '1st Party Applications',
      filter: item => ['BizLeads', 'business.comcast.com', 'Enterprise Call Center', 'Gated Content', 'masergy.com'].includes(item.username)
    },
    thirdParty: {
      title: '3rd Party Integrations',
      filter: item => ['BrightTALK', 'GMR', 'pharosIQ', 'TechTarget'].includes(item.username)
    },
    social: {
      title: 'Social Networks',
      filter: item => ['Instagram', 'LinkedIn', 'TikTok'].includes(item.username)
    }
  };

  // Filter items by category
  const categorizedItems = Object.entries(categories).reduce((acc, [key, category]) => {
    acc[key] = items.filter(category.filter);
    return acc;
  }, {});

  return (
    <div className="w-full space-y-6">
      <Tabs defaultValue="firstParty" className="w-full">
        <TabsList className="grid w-full grid-cols-3 h-24 bg-gray-50/50">
          <TabsTrigger 
            value="firstParty" 
            className="flex flex-col items-center gap-2 data-[state=active]:bg-white"
          >
            <Building2 className="h-6 w-6" />
            <span className="text-sm font-medium">1st Party Applications</span>
            <div className="h-1 w-full absolute bottom-0 bg-blue-500 scale-x-0 transition-transform data-[state=active]:scale-x-100" />
          </TabsTrigger>
          <TabsTrigger 
            value="thirdParty" 
            className="flex flex-col items-center gap-2 data-[state=active]:bg-white"
          >
            <Webhook className="h-6 w-6" />
            <span className="text-sm font-medium">3rd Party Integrations</span>
            <div className="h-1 w-full absolute bottom-0 bg-blue-500 scale-x-0 transition-transform data-[state=active]:scale-x-100" />
          </TabsTrigger>
          <TabsTrigger 
            value="social" 
            className="flex flex-col items-center gap-2 data-[state=active]:bg-white"
          >
            <Share2 className="h-6 w-6" />
            <span className="text-sm font-medium">Social Networks</span>
            <div className="h-1 w-full absolute bottom-0 bg-blue-500 scale-x-0 transition-transform data-[state=active]:scale-x-100" />
          </TabsTrigger>
        </TabsList>

        {Object.entries(categories).map(([key, category]) => (
          <TabsContent key={key} value={key} className="mt-6">
            <Card>
              <CardHeader>
                <CardTitle>{category.title}</CardTitle>
              </CardHeader>
              <CardContent>
                <UsersList
                  items={categorizedItems[key]}
                  showDivisionFilters={false}
                  showStatusFilters={false}
                  onlineUsersComponent={null}
                />
              </CardContent>
            </Card>
          </TabsContent>
        ))}
      </Tabs>
    </div>
  );
};

export default CategorizedAPIList;
