import React, { useState, useCallback, createContext, useContext } from "react";
import { AlertCircle } from "lucide-react";
import { Alert, AlertDescription } from "../../teamhub/components/ui/alert";

const AudienceDataContext = createContext({
  audienceData: {
    summary: {
      companies: 0,
      contacts: 0,
      addresses: 0,
      workEmails: 0,
      personalEmails: 0,
      directPhones: 0,
      mobilePhones: 0,
      linkedinProfiles: 0,
    },
    topCompanies: [],
    distribution: {
      companySizes: [],
      industries: [],
    },
    geoDistribution: [],
  },
  isLoading: false,
  error: null,
  fetchAllData: () => {},
  clearError: () => {},
});

const AudienceDataProvider = ({ children, auth, filters }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [audienceData, setAudienceData] = useState({
    summary: {
      companies: 0,
      contacts: 0,
      addresses: 0,
      workEmails: 0,
      personalEmails: 0,
      directPhones: 0,
      mobilePhones: 0,
      linkedinProfiles: 0,
    },
    topCompanies: [],
    distribution: {
      companySizes: [],
      industries: [],
    },
    geoDistribution: [],
  });

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  const handleApiError = useCallback((error, endpoint) => {
    console.error(`Error fetching data from ${endpoint}:`, error);
    let errorMessage = "An error occurred while fetching audience data. ";

    if (error.response) {
      errorMessage += `Server responded with status ${error.response.status}`;
    } else if (error.request) {
      errorMessage += "No response received from server";
    } else {
      errorMessage += error.message;
    }

    setError(errorMessage);
  }, []);

  const makeApiRequest = useCallback(
    async (endpoint, filters) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}${endpoint}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${auth.token}`,
            },
            body: JSON.stringify(filters),
          },
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
      } catch (error) {
        console.error(`Error in makeApiRequest (${endpoint}):`, error);
        throw error;
      }
    },
    [auth.token],
  );

  const validateFilters = useCallback((filters) => {
    if (!filters) {
      throw new Error("Filters are required");
    }

    const requiredArrays = [
      "jobLevels",
      "departments",
      "companySizes",
      "revenueRanges",
      "verticals",
      "locations",
      "hqDivisions",
      "jobFunctions",
      "topicClusters",
    ];

    for (const field of requiredArrays) {
      if (!Array.isArray(filters[field])) {
        throw new Error(`${field} must be an array`);
      }
    }

    if (!filters.exclusions || typeof filters.exclusions !== "object") {
      throw new Error("exclusions must be an object");
    }
  }, []);

  const fetchAllData = useCallback(
    async (filters) => {
      try {
        setIsLoading(true);
        setError(null);

        validateFilters(filters);
        console.log("Validated Filters:", filters);

        const endpoints = {
          summary: "/audiences/calculate",
          topCompanies: "/audiences/top-companies",
          distribution: "/audiences/distribution",
          geoDistribution: "/audiences/geo-distribution",
        };

        const results = await Promise.all(
          Object.entries(endpoints).map(async ([key, endpoint]) => {
            console.log(`Fetching ${key} data from ${endpoint}`);
            const data = await makeApiRequest(endpoint, filters);
            console.log(`${key} data fetched:`, data);
            return data;
          }),
        );

        const [summary, topCompanies, distribution, geoDistribution] = results;

        setAudienceData({
          summary,
          topCompanies,
          distribution,
          geoDistribution,
        });

        console.log("All audience data fetched successfully!");
      } catch (error) {
        console.error("Error in fetchAllData:", error);
        handleApiError(error, "multiple endpoints");
      } finally {
        setIsLoading(false);
      }
    },
    [makeApiRequest, validateFilters, handleApiError],
  );

  return (
    <AudienceDataContext.Provider
      value={{
        audienceData,
        isLoading,
        error,
        fetchAllData,
        clearError,
      }}
    >
      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      {children}
    </AudienceDataContext.Provider>
  );
};

export const useAudienceData = () => {
  const context = useContext(AudienceDataContext);
  if (context === undefined) {
    throw new Error(
      "useAudienceData must be used within an AudienceDataProvider",
    );
  }
  return context;
};

export default AudienceDataProvider;
