import React, { useState, useEffect, useContext } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import {
  MessageSquare,
  Calendar,
  FileUp,
  Users,
  Clock,
  Search,
  Target,
  TrendingUp,
  TrendingDown,
  FileSpreadsheet,
  UserCog,
  Star,
  ArrowUp,
  ArrowDown,
  ChevronDown,
  BarChart2,
  Mail,
  UserPlus,
  Link as LinkIcon,
  Copy,
} from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../shared/components/UIElements/alert-dialog";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "../components/ui/dialog";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import { Input } from "../components/ui/input";
import { Button } from "../../user/components/ui/button";
import { Card, CardHeader, CardContent } from "../../user/components/ui/card";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import TeamCalendarModal from "../components/TeamCalendarModal";
import UpcomingEventsPreview from "../components/UpcomingEventsPreview";
import TeamCampaigns from "../components/TeamCampaigns";
import TeamEvents from "../components/TeamEvents";
import TeamLeads from "../components/TeamLeads";
import defaultUserImage from "../../user/components/user.png";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

const InviteTeamModal = () => {
  const [emails, setEmails] = useState("");
  const [selectedRole, setSelectedRole] = useState("team-member");
  const [message, setMessage] = useState("");
  const [inviteLink, setInviteLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const teamName = localStorage.getItem("team") || "Team Hub";

  const resetForm = () => {
    setEmails("");
    setMessage("");
    setInviteLink("");
    setError(null);
    setSuccess(null);
    setSelectedRole("team-member");
  };

  const handleSendInvites = async () => {
    setIsLoading(true);
    setError(null);
    setSuccess(null);
    try {
      const emailList = emails
        .split("\n")
        .map((email) => email.trim())
        .filter((email) => email);
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/team/invite`,
        "POST",
        JSON.stringify({
          emails: emailList,
          team: teamName,
          team_role: selectedRole,
          message: message,
        }),
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      );

      setSuccess(
        `Successfully sent invites to ${emailList.length} email${emailList.length === 1 ? "" : "s"}`,
      );
      resetForm();

      // Close modal after 2 seconds
      setTimeout(() => {
        setIsOpen(false);
      }, 2000);
    } catch (err) {
      setError(err.message || "Failed to send invites");
    }
    setIsLoading(false);
  };

  const handleGenerateLink = async () => {
    setIsLoading(true);
    setError(null);
    setSuccess(null);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/team/invite-link`,
        "POST",
        JSON.stringify({
          team: teamName,
          team_role: selectedRole,
        }),
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      );

      setInviteLink(response.invite_link);
      setSuccess("New invite link generated successfully");
    } catch (err) {
      setError(err.message || "Failed to generate invite link");
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    setIsOpen(false);
    resetForm();
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <button className="inline-flex items-center gap-2 px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-lg hover:bg-blue-700 transition-colors">
          <UserPlus className="h-4 w-4" />
          Invite Team Members
        </button>
      </DialogTrigger>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Invite Team Members</DialogTitle>
          <DialogDescription>Add members to {teamName}</DialogDescription>
        </DialogHeader>

        {error && (
          <div className="bg-red-50 text-red-700 p-3 rounded-md mb-4">
            {error}
          </div>
        )}

        {success && (
          <div className="bg-green-50 text-green-700 p-3 rounded-md mb-4">
            {success}
          </div>
        )}

        <Tabs defaultValue="email" className="mt-4">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="email">
              <Mail className="h-4 w-4 mr-2" />
              Email Invites
            </TabsTrigger>
            <TabsTrigger value="link">
              <LinkIcon className="h-4 w-4 mr-2" />
              Invite Link
            </TabsTrigger>
          </TabsList>

          <TabsContent value="email">
            <div className="space-y-4 mt-4">
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-900">
                  Email Addresses
                </label>
                <textarea
                  placeholder="Enter email addresses (one per line)"
                  className="w-full min-h-[120px] px-3 py-2 text-sm rounded-md border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={emails}
                  onChange={(e) => setEmails(e.target.value)}
                />
                <p className="text-xs text-gray-500">
                  Team members will receive an email invitation to join your
                  team
                </p>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-900">
                  Team Role
                </label>
                <select
                  className="w-full px-3 py-2 text-sm rounded-md border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value)}
                >
                  <option value="team-member">Team Member</option>
                  <option value="team-admin">Team Admin</option>
                </select>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-900">
                  Add a personal message (optional)
                </label>
                <textarea
                  placeholder="Enter a custom message for your invitation"
                  className="w-full min-h-[80px] px-3 py-2 text-sm rounded-md border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>

            <DialogFooter className="mt-6">
              <Button variant="outline" className="mr-2" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                onClick={handleSendInvites}
                disabled={isLoading || !emails.trim()}
              >
                {isLoading ? "Sending..." : "Send Invites"}
              </Button>
            </DialogFooter>
          </TabsContent>

          <TabsContent value="link">
            <div className="space-y-4 mt-4">
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-900">
                  Share Invite Link
                </label>
                <div className="flex gap-2">
                  <Input
                    value={inviteLink}
                    readOnly
                    className="flex-1"
                    placeholder="Generate a link to invite team members"
                  />
                  <Button
                    variant="outline"
                    onClick={() => {
                      navigator.clipboard.writeText(inviteLink);
                      setSuccess("Link copied to clipboard!");
                    }}
                    disabled={!inviteLink}
                  >
                    <Copy className="h-4 w-4" />
                  </Button>
                </div>
                <p className="text-xs text-gray-500">
                  Share this link to invite team members. New members will be
                  assigned the Team Member role by default.
                </p>
              </div>

              <div className="space-y-2 mt-4">
                <label className="text-sm font-medium text-gray-900">
                  Default Role for Link Invites
                </label>
                <select
                  className="w-full px-3 py-2 text-sm rounded-md border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value)}
                >
                  <option value="team-member">Team Member</option>
                  <option value="team-admin">Team Admin</option>
                </select>
              </div>
            </div>

            <DialogFooter className="mt-6">
              <Button variant="outline" className="mr-2" onClick={handleClose}>
                Cancel
              </Button>
              <Button onClick={handleGenerateLink} disabled={isLoading}>
                {isLoading ? "Generating..." : "Generate New Link"}
              </Button>
            </DialogFooter>
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
};

const MetricCard = ({ label, value, trending, icon: Icon }) => (
  <Card className="w-full">
    <CardContent className="h-32 flex items-center justify-center p-6">
      <div className="flex flex-col items-center text-center pt-4">
        {Icon && (
          <div className="mb-2">
            <Icon className="w-6 h-6 text-blue-600" />
          </div>
        )}
        <p className="text-sm font-medium text-gray-500 mb-1">{label}</p>
        <div className="flex items-center space-x-2">
          <span className="text-2xl font-semibold text-gray-900">
            {typeof value === "number" ? value.toLocaleString() : value}
          </span>
          {trending != null && (
            <span
              className={`text-sm ${trending >= 0 ? "text-green-600" : "text-red-600"}`}
            >
              {trending > 0 ? "+" : ""}
              {trending}%
            </span>
          )}
        </div>
      </div>
    </CardContent>
  </Card>
);

const CampaignCard = ({ campaign }) => {
  const {
    name = "Unnamed Campaign",
    status = "unknown",
    dateRange = "No date range",
    owner = "Unassigned",
    metrics = {
      leads: { total: 0, trending: 0 },
      opportunities: { total: 0, trending: 0 },
      engagement: {
        emailOpen: 0,
        trending: 0,
        clickRate: 0,
      },
    },
  } = campaign;

  const formatNumber = (num) => {
    if (num >= 1000000) {
      return `${(num / 1000000).toFixed(1)}M`;
    } else if (num >= 1000) {
      return `${(num / 1000).toFixed(1)}K`;
    }
    return num.toLocaleString();
  };

  return (
    <div className="py-4 first:pt-0 last:pb-0">
      <div className="flex items-start justify-between">
        <div className="flex items-start space-x-3">
          <div className="mt-1">
            <Star
              className={`h-4 w-4 ${
                campaign.isPriority
                  ? "text-yellow-400 fill-current"
                  : "text-gray-300"
              }`}
            />
          </div>
          <div>
            <div className="flex items-center space-x-2">
              <h3 className="font-medium text-gray-900">{name}</h3>
              <span
                className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${
                  status === "active"
                    ? "bg-green-100 text-green-800"
                    : status === "completed"
                      ? "bg-blue-100 text-blue-800"
                      : "bg-gray-100 text-gray-800"
                }`}
              >
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </span>
            </div>
            <div className="mt-1 flex items-center space-x-2 text-sm text-gray-500">
              <span>{dateRange}</span>
              <span>•</span>
              <span>Owner: {owner}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 grid grid-cols-3 gap-4">
        <div className="flex items-center space-x-2">
          <div className="p-2 bg-blue-50 rounded-lg">
            <Users className="h-4 w-4 text-blue-600" />
          </div>
          <div>
            <div className="flex items-center space-x-1">
              <span className="font-medium">
                {formatNumber(metrics.leads.total)}
              </span>
              {metrics.leads.trending !== 0 && (
                <span
                  className={`text-xs ${
                    metrics.leads.trending >= 0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {metrics.leads.trending > 0 ? "+" : ""}
                  {metrics.leads.trending}%
                </span>
              )}
            </div>
            <p className="text-xs text-gray-500">Total Leads</p>
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <div className="p-2 bg-purple-50 rounded-lg">
            <Target className="h-4 w-4 text-purple-600" />
          </div>
          <div>
            <div className="flex items-center space-x-1">
              <span className="font-medium">
                {formatNumber(metrics.opportunities.total)}
              </span>
              {metrics.opportunities.trending !== 0 && (
                <span
                  className={`text-xs ${
                    metrics.opportunities.trending >= 0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {metrics.opportunities.trending > 0 ? "+" : ""}
                  {metrics.opportunities.trending}%
                </span>
              )}
            </div>
            <p className="text-xs text-gray-500">Opportunities</p>
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <div className="p-2 bg-green-50 rounded-lg">
            <Mail className="h-4 w-4 text-green-600" />
          </div>
          <div>
            <div className="flex items-center space-x-1">
              <span className="font-medium">
                {metrics.engagement.emailOpen}%
              </span>
              {metrics.engagement.trending !== 0 && (
                <span
                  className={`text-xs ${
                    metrics.engagement.trending >= 0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {metrics.engagement.trending > 0 ? "+" : ""}
                  {metrics.engagement.trending}%
                </span>
              )}
            </div>
            <div className="flex items-center">
              <p className="text-xs text-gray-500">Email Engagement</p>
              {metrics.engagement.clickRate > 0 && (
                <span className="text-xs text-gray-400 ml-1">
                  ({metrics.engagement.clickRate}% CTR)
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LoadingMetricCard = () => (
  <Card className="w-full">
    <CardContent className="h-32 flex items-center justify-center p-6">
      <div className="flex flex-col items-center text-center pt-4 w-full animate-pulse">
        <div className="w-6 h-6 bg-gray-200 rounded-full mb-2" />
        <div className="h-4 w-24 bg-gray-200 rounded mb-2" />
        <div className="h-8 w-32 bg-gray-200 rounded" />
      </div>
    </CardContent>
  </Card>
);

const LoadingCampaignCard = () => (
  <div className="py-4 first:pt-0 last:pb-0 animate-pulse">
    <div className="flex items-start justify-between">
      <div className="flex items-start space-x-3">
        <div className="w-4 h-4 mt-1 bg-gray-200 rounded" />
        <div>
          <div className="h-6 w-48 bg-gray-200 rounded mb-2" />
          <div className="h-4 w-32 bg-gray-200 rounded" />
        </div>
      </div>
    </div>
    <div className="mt-4 grid grid-cols-3 gap-4">
      {[1, 2, 3].map((i) => (
        <div key={i} className="flex items-center space-x-2">
          <div className="w-8 h-8 bg-gray-200 rounded-lg" />
          <div>
            <div className="h-5 w-16 bg-gray-200 rounded mb-1" />
            <div className="h-4 w-20 bg-gray-200 rounded" />
          </div>
        </div>
      ))}
    </div>
  </div>
);

const TeamHub = () => {
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
  const [activeTab, setActiveTab] = useState("overview");
  const [activeCampaign, setActiveCampaign] = useState("all");
  const [teamMembers, setTeamMembers] = useState([]);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [totalLeads, setTotalLeads] = useState(0);
  const [totalUploads, setTotalUploads] = useState(0);
  const [recentUploads, setRecentUploads] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [totalCampaigns, setTotalCampaigns] = useState(0);
  const [expandedCampaign, setExpandedCampaign] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [eventsRefreshTrigger, setEventsRefreshTrigger] = useState(0);
  const { isLoading, error, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const checkProfileCompletion = () => {
      const userTeam = localStorage.getItem("team");
      const userDivision = localStorage.getItem("division");

      if (!userTeam || !userDivision) {
        setShowProfileModal(true);
        return false;
      }
      return true;
    };

    const fetchTeamMembers = async () => {
      try {
        if (!checkProfileCompletion()) return;

        const userTeam = localStorage.getItem("team");
        const userDivision = localStorage.getItem("division");

        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/users/all`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          },
        );

        const filteredUsers = responseData
          .filter(
            (user) =>
              user.team === userTeam &&
              user.division === userDivision &&
              !user.api_account &&
              user.active,
          )
          .map((user) => ({
            ...user,
            avatar: user.image_url || defaultUserImage,
            status: user.status || "active",
          }));

        let accumulatedLeads = 0;
        let accumulatedUploads = 0;

        const usersWithActivity = await Promise.all(
          filteredUsers.map(async (user) => {
            try {
              const activityData = await sendRequest(
                `${process.env.REACT_APP_API_URL}/users/activity/${user.user_id}`,
                "GET",
                null,
                {
                  Authorization: `Bearer ${auth.token}`,
                },
              );

              accumulatedLeads += activityData.lead_count || 0;
              accumulatedUploads += activityData.upload_count || 0;

              return {
                ...user,
                leads: activityData.lead_count || 0,
                uploads: activityData.upload_count || 0,
              };
            } catch (err) {
              console.error(
                `Error fetching activity for user ${user.user_id}:`,
                err,
              );
              return {
                ...user,
                leads: 0,
                uploads: 0,
              };
            }
          }),
        );

        setTotalLeads(accumulatedLeads);
        setTotalUploads(accumulatedUploads);
        setTeamMembers(usersWithActivity);
        await fetchRecentUploads(filteredUsers);
        await fetchTeamCampaigns(filteredUsers);
      } catch (err) {
        console.error("Error fetching team members:", err);
      } finally {
        setIsLoadingInitialData(false);
      }
    };

    if (auth.token) {
      fetchTeamMembers();
    }
  }, [sendRequest, auth.token]);

  const fetchRecentUploads = async (teamMembers) => {
    try {
      let accumulatedUploads = 0;

      const uploadsPromises = teamMembers.map(async (member) => {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_API_URL}/uploads/${member.user_id}`,
            "GET",
            null,
            {
              Authorization: `Bearer ${auth.token}`,
            },
          );
          accumulatedUploads += responseData.length;
          return responseData;
        } catch (err) {
          console.error(
            `Error fetching uploads for user ${member.user_id}:`,
            err,
          );
          return [];
        }
      });

      const uploadsArrays = await Promise.all(uploadsPromises);
      const allUploads = uploadsArrays.flat();
      const sortedUploads = allUploads.sort(
        (a, b) => new Date(b.upload_date) - new Date(a.upload_date),
      );
      setRecentUploads(sortedUploads.slice(0, 5));
      setTotalUploads(accumulatedUploads);
    } catch (err) {
      console.error("Error fetching recent uploads:", err);
    }
  };

  const fetchTeamCampaigns = async (teamMembers) => {
    try {
      const campaignsPromises = teamMembers.map(async (member) => {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_API_URL}/campaigns/user/${member.user_id}`,
            "GET",
            null,
            {
              Authorization: `Bearer ${auth.token}`,
            },
          );
          return responseData;
        } catch (err) {
          console.error(
            `Error fetching campaigns for user ${member.user_id}:`,
            err,
          );
          return [];
        }
      });

      const campaignsArrays = await Promise.all(campaignsPromises);
      const allCampaigns = campaignsArrays.flat();

      const campaignsById = {};
      allCampaigns.forEach((campaign) => {
        campaignsById[campaign.campaign_id] = {
          ...campaign,
          metrics: {
            leads: {
              total: 0,
              trending: 0,
            },
            opportunities: {
              total: 0,
              trending: 0,
            },
            engagement: {
              emailOpen: 0,
              trending: 0,
              clickRate: 0,
            },
          },
          isPriority: campaign.priority || campaign.isFollowed || false,
          name: campaign.name,
          status: campaign.status,
          dateRange: campaign.dateRange,
          owner: campaign.owner,
          trend: campaign.trend,
        };
      });

      const uniqueCampaigns = Object.values(campaignsById);
      const sortedCampaigns = uniqueCampaigns.sort(
        (a, b) => new Date(b.created_date) - new Date(a.created_date),
      );

      const campaignsList = [
        { campaign_id: "all", name: "All Campaigns" },
        ...sortedCampaigns,
      ];

      setCampaigns(campaignsList);
      setTotalCampaigns(sortedCampaigns.length);
    } catch (err) {
      console.error("Error fetching team campaigns:", err);
    }
  };

  const getMemberName = (userId) => {
    const member = teamMembers.find((member) => member.user_id === userId);
    return member ? member.username : "Unknown User";
  };

  const sortedTeamMembers = teamMembers.sort((a, b) => {
    if (a.team_role === "team-admin" && b.team_role !== "team-admin") return -1;
    if (a.team_role !== "team-admin" && b.team_role === "team-admin") return 1;
    return a.username.localeCompare(b.username);
  });

  const teamName = localStorage.getItem("team") || "Team Hub";

  const priorityCampaigns = campaigns.filter(
    (campaign) =>
      campaign.campaign_id !== "all" &&
      campaign.priority === true &&
      (campaign.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        campaign.owner?.toLowerCase().includes(searchTerm.toLowerCase())),
  );

  if (isLoadingInitialData) {
    return (
      <div className="min-h-screen bg-gray-50">
        <div className="bg-white border-b">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center space-x-8">
                <div className="h-8 w-48 bg-gray-200 rounded animate-pulse" />
                <div className="flex space-x-4">
                  {["Overview", "Campaigns", "Events", "Leads"].map((tab) => (
                    <div
                      key={tab}
                      className="h-8 w-20 bg-gray-200 rounded animate-pulse"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-[1440px] mx-auto px-8 py-8 pb-20">
          <div className="flex gap-10">
            <div className="w-72 space-y-6">
              <Card>
                <CardHeader className="px-6 pb-4">
                  <div className="flex items-center justify-between animate-pulse">
                    <div className="h-5 w-32 bg-gray-200 rounded" />
                    <div className="h-4 w-20 bg-gray-200 rounded" />
                  </div>
                </CardHeader>
                <CardContent className="px-6">
                  <div className="space-y-4">
                    {[1, 2, 3, 4].map((i) => (
                      <div
                        key={i}
                        className="flex items-center space-x-3 animate-pulse"
                      >
                        <div className="w-8 h-8 bg-gray-200 rounded-full" />
                        <div className="flex-1">
                          <div className="h-4 w-24 bg-gray-200 rounded mb-2" />
                          <div className="h-3 w-16 bg-gray-200 rounded" />
                        </div>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader className="px-6 pb-4">
                  <div className="h-5 w-32 bg-gray-200 rounded animate-pulse" />
                </CardHeader>
                <CardContent className="px-6">
                  <div className="space-y-2">
                    {[1, 2, 3].map((i) => (
                      <div
                        key={i}
                        className="h-8 w-full bg-gray-200 rounded animate-pulse"
                      />
                    ))}
                  </div>
                </CardContent>
              </Card>
            </div>

            <div className="flex-1 min-w-0">
              <div className="grid grid-cols-3 gap-6 mb-8">
                {[1, 2, 3].map((i) => (
                  <LoadingMetricCard key={i} />
                ))}
              </div>

              <Card>
                <CardHeader className="px-6 pb-4">
                  <div className="flex items-center justify-between animate-pulse">
                    <div className="h-6 w-48 bg-gray-200 rounded" />
                    <div className="h-5 w-32 bg-gray-200 rounded" />
                  </div>
                </CardHeader>
                <CardContent className="p-6">
                  <div className="space-y-6">
                    {[1, 2, 3].map((i) => (
                      <LoadingCampaignCard key={i} />
                    ))}
                  </div>
                </CardContent>
              </Card>
            </div>

            <div className="w-96 space-y-6">
              <Card>
                <CardHeader className="px-6 pb-4">
                  <div className="flex items-center justify-between animate-pulse">
                    <div className="h-5 w-24 bg-gray-200 rounded" />
                    <div className="h-5 w-24 bg-gray-200 rounded" />
                  </div>
                </CardHeader>
                <CardContent className="px-6">
                  <div className="space-y-4">
                    {[1, 2, 3].map((i) => (
                      <div
                        key={i}
                        className="h-16 w-full bg-gray-200 rounded animate-pulse"
                      />
                    ))}
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader className="px-6 pb-4">
                  <div className="h-5 w-32 bg-gray-200 rounded animate-pulse" />
                </CardHeader>
                <CardContent className="px-6">
                  <div className="space-y-4">
                    {[1, 2, 3].map((i) => (
                      <div
                        key={i}
                        className="flex items-center space-x-3 animate-pulse"
                      >
                        <div className="w-10 h-10 bg-gray-200 rounded-lg" />
                        <div className="flex-1">
                          <div className="h-4 w-48 bg-gray-200 rounded mb-2" />
                          <div className="h-3 w-32 bg-gray-200 rounded" />
                        </div>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <AlertDialog open={showProfileModal} onOpenChange={setShowProfileModal}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Complete Your Profile</AlertDialogTitle>
            <AlertDialogDescription>
              To use the Team Hub, you need to complete your profile setup by
              adding your team and division information.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction
              onClick={() => history.push("/user-settings")}
              className="inline-flex items-center"
            >
              <UserCog className="mr-2 h-4 w-4" />
              Go to Settings
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center space-x-8">
              <h1 className="text-xl font-semibold text-gray-900">
                {teamName}
              </h1>
              <div className="flex space-x-4">
                {["Overview", "Campaigns", "Events", "Leads"].map((tab) => (
                  <button
                    key={tab}
                    onClick={() => {
                      setActiveTab(tab.toLowerCase());
                      history.push(
                        tab.toLowerCase() === "overview"
                          ? "/team-hub"
                          : `/team-hub/${tab.toLowerCase() === "leads" ? "team-leads" : tab.toLowerCase()}`,
                      );
                    }}
                    className={`px-3 py-2 text-sm font-medium ${
                      location.pathname === `/team-hub/${tab.toLowerCase()}` ||
                      (location.pathname === "/team-hub/team-leads" &&
                        tab === "Leads") ||
                      (location.pathname === "/team-hub" &&
                        tab.toLowerCase() === "overview")
                        ? "text-blue-600 border-b-2 border-blue-600"
                        : "text-gray-500 hover:text-gray-700"
                    }`}
                  >
                    {tab}
                  </button>
                ))}
              </div>
            </div>
            <div>
              <InviteTeamModal />
            </div>
          </div>
        </div>
      </div>

      <Switch>
        <Route exact path="/team-hub">
          <div className="max-w-[1440px] mx-auto px-8 py-8 pb-20">
            <div className="flex gap-10">
              {/* Left Sidebar */}
              <div className="w-72 space-y-6">
                {/* Team Section */}
                <Card>
                  <CardHeader className="px-6 pb-4">
                    <div className="flex items-center justify-between">
                      <h2 className="text-sm font-medium">Team Members</h2>
                      <span className="text-xs text-gray-500">
                        {teamMembers.length} members
                      </span>
                    </div>
                  </CardHeader>
                  <CardContent className="px-6">
                    <div className="space-y-4">
                      {sortedTeamMembers.map((member) => (
                        <div
                          key={member.user_id}
                          className="flex items-center space-x-3"
                        >
                          <div className="relative">
                            <img
                              src={member.avatar}
                              alt=""
                              className="w-8 h-8 rounded-full"
                            />
                            <div
                              className={`absolute bottom-0 right-0 w-2.5 h-2.5 rounded-full border-2 border-white ${
                                member.status === "Available"
                                  ? "bg-green-500"
                                  : member.status === "Away"
                                    ? "bg-yellow-500"
                                    : member.status === "Busy"
                                      ? "bg-red-500"
                                      : "bg-gray-300"
                              }`}
                            />
                          </div>
                          <div className="flex-1">
                            <p className="text-sm font-medium">
                              {member.username}
                            </p>
                            {member.team_role === "team-admin" && (
                              <p className="text-xs text-gray-500">
                                Team Admin
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </CardContent>
                </Card>

                {/* Campaigns Section */}
                <Card>
                  <CardHeader className="px-6 pb-4">
                    <h2 className="text-sm font-medium">Active Campaigns</h2>
                  </CardHeader>
                  <CardContent className="px-6">
                    <div className="space-y-2">
                      {campaigns.map((campaign) => (
                        <button
                          key={campaign.campaign_id}
                          onClick={() =>
                            setActiveCampaign(campaign.campaign_id)
                          }
                          className={`w-full text-left px-3 py-2 rounded-md text-sm ${
                            activeCampaign === campaign.campaign_id
                              ? "bg-blue-50 text-blue-700"
                              : "text-gray-700 hover:bg-gray-50"
                          }`}
                        >
                          {campaign.name}
                        </button>
                      ))}
                    </div>
                  </CardContent>
                </Card>
              </div>

              {/* Main Content Area */}
              <div className="flex-1 min-w-0">
                {/* Metrics Overview */}
                <div className="grid grid-cols-3 gap-6 mb-8">
                  <MetricCard
                    label="Total Leads"
                    value={totalLeads}
                    trending={12.5}
                    icon={Users}
                  />
                  <MetricCard
                    label="Total Uploads"
                    value={totalUploads}
                    trending={5}
                    icon={FileUp}
                  />
                  <MetricCard
                    label="Active Campaigns"
                    value={totalCampaigns}
                    trending={1}
                    icon={FileSpreadsheet}
                  />
                </div>

                {/* Priority Campaigns Section */}
                <Card>
                  <CardHeader className="px-6 pb-4">
                    <div className="flex items-center justify-between">
                      <h2 className="text-lg font-medium">
                        Priority Campaigns
                      </h2>
                      <button
                        onClick={() => history.push("/team-hub/campaigns")}
                        className="text-sm text-blue-600 hover:text-blue-700"
                      >
                        View All Campaigns
                      </button>
                    </div>
                  </CardHeader>
                  <CardContent className="p-6">
                    <div className="space-y-6">
                      {priorityCampaigns.map((campaign) => (
                        <CampaignCard
                          key={campaign.campaign_id}
                          campaign={campaign}
                          isExpanded={expandedCampaign === campaign.campaign_id}
                          onToggleExpand={setExpandedCampaign}
                        />
                      ))}
                    </div>
                  </CardContent>
                </Card>
              </div>

              {/* Right Sidebar */}
              <div className="w-96 space-y-6">
                {/* Calendar Card */}
                <Card>
                  <CardHeader className="px-6 pb-4">
                    <div className="flex items-center justify-between">
                      <h2 className="text-sm font-medium">Events</h2>
                      <button
                        onClick={() => setIsCalendarOpen(true)}
                        className="text-sm text-blue-600 hover:text-blue-700"
                      >
                        View Calendar
                      </button>
                    </div>
                  </CardHeader>
                  <CardContent className="px-6">
                    <UpcomingEventsPreview
                      teamMembers={teamMembers}
                      auth={auth}
                      refreshTrigger={eventsRefreshTrigger}
                    />
                  </CardContent>
                </Card>

                {/* Recent Uploads */}
                <Card>
                  <CardHeader className="px-6 pb-4">
                    <div className="flex items-center justify-between">
                      <h2 className="text-sm font-medium">Recent Uploads</h2>
                    </div>
                  </CardHeader>
                  <CardContent className="px-6">
                    <div className="space-y-4">
                      {recentUploads.map((file, index) => (
                        <div
                          key={index}
                          className="flex items-center space-x-3"
                        >
                          <div className="w-10 h-10 bg-gray-100 rounded-lg flex items-center justify-center">
                            <FileUp className="w-5 h-5 text-gray-600" />
                          </div>
                          <div>
                            <p className="text-sm font-medium text-gray-900">
                              {file.file_name}
                            </p>
                            <p className="text-xs text-gray-500">
                              Added by {getMemberName(file.created_by)} •{" "}
                              {new Date(file.upload_date).toLocaleString()}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </Route>
        <Route path="/team-hub/campaigns">
          <TeamCampaigns />
        </Route>
        <Route path="/team-hub/events">
          <TeamEvents teamMembers={teamMembers} />
        </Route>
        <Route path="/team-hub/team-leads">
          <TeamLeads teamMembers={teamMembers} />
        </Route>
      </Switch>

      <TeamCalendarModal
        isOpen={isCalendarOpen}
        onClose={() => setIsCalendarOpen(false)}
        teamMembers={teamMembers}
        auth={auth}
        onEventAdded={() => setEventsRefreshTrigger((prev) => prev + 1)}
      />
    </div>
  );
};

export default TeamHub;
